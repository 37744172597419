.lb-title {
    display: none;
}

.company-details {
    font: 400 16px $font-family;
    padding-right: 35px;
    @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-top: 20px;
    }
    p {
        color: $gray-darker;
        line-height: 200%;
        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }
    }
    .company {
        padding: 70px 0;
        @include media-breakpoint-down(md) {
            padding: 20px 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}

#footer {
    margin-top: 35px;
    @include media-breakpoint-down(lg) {
        margin-top: 40px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 25px;
    }
}

.block_newsletter {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
    .form-container {
        padding: 60px 0 0 60px;
        @include media-breakpoint-down(xs) {
            padding: 40px 0 0 12px;
        }
    }
    h1 {
        font-size: 26px;
        padding-top: 70px;
        @include media-breakpoint-down(md) {
            padding: 20px 0;
            font-size: 20px;
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }
    @include search-box;
    font-size: $font-size-sm;
    #block-newsletter-label {
        color: #fff;
        font: 500 30px $font-family;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            padding: 0 0 10px 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
    .newsletter-description {
        color: #fff;
        font: 400 20px $font-family;
    }
    form {
        .input-wrapper {
            overflow: hidden;
            display: flex;
            @include media-breakpoint-down(xs) {
                display: block;
                position: relative;
                height: 70px;
            }
            input {
                width: 310px;
                border: none;
                padding-left: 20px;
                border-radius: 25px;
                height: 45px;
            }
            input.btn-info {
                width: 120px;
                padding: 0;
                margin-left: 10px;
                background-color: $brand-primary;
                &:hover {
                    background-color: #313131;
                }
                @include media-breakpoint-down(xs) {
                    position: absolute;
                    right: 0;
                }
            }
            @include media-breakpoint-down(sm) {
            }
        }
        input[type=text] {
            padding: 11px;
            &:focus {
                border: 3px $brand-primary solid;
                padding: 8px 8px 9px;
                outline: 0;
            }
            width: 100%;
        }
        input {
            @include media-breakpoint-down(md) {}
        }
    }
    margin-bottom: 0.625rem;
    .newsletter_tooltip {
        display: block;
        position: absolute;
        top: 51px;
        width: 100%;
        @include media-breakpoint-down(xs) {
            top: 48px;
        }
        .alert {
            padding: 0;
            text-align: center;
            @include border-radius(20px);
        }
        .wrapped-error {
            background-color: $brand-danger;
        }
        .wrapped-success {
            background-color: $brand-success;
            color: #fff;
            text-align: center;
            @include border-radius(20px);
        }
    }
}

.block-contact {
    #footer_sub_menu_contact {
        @include media-breakpoint-down(sm) {
            p {
                color: $gray-darker;
                cursor: pointer;
                font: 400 13px $font-family;
                background: #fff;
                padding: 10px 10px 10px 15px;
                border-bottom: 1px solid $border-gray;
                margin-bottom: 0;
                .imag {
                    img {
                        margin-right: 13px;
                    }
                }
                .indent {
                    margin-left: 29px;
                }
                a {
                    color: $gray-darker;
                    cursor: pointer;
                    font: 400 13px $font-family;
                }
            }
        }
    }
}

.block-contact #footer_sub_menu_contact p .imag img {
    max-width: 18px;
    height: auto;
}

.block-contact #footer_sub_menu_contact p {
    display: flex;
}

.linklist {
    .blockcms-title a {
        color: $gray-darker;
    }
}

.account-list a {
    color: $gray;
    &:hover {
        color: $brand-primary;
    }
}

.blockcms-title,
.myaccount-title,
.myaccount-title a {
    font-weight: 700;
    font-size: $base-font-size;
}

.block-social {
    h1 {
        font-size: 26px;
        padding-top: 70px;
        @include media-breakpoint-down(md) {
            padding: 20px 0;
            font-size: 20px;
        }
    }
    ul {
        text-align: left;
        display: inline-flex;
        margin-top: 25px;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-down(md) {
        text-align: left !important;
    }
}

.block-social li {
    height: 2.5rem;
    width: 1rem;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin: 0.125rem;
    margin-right: 15px;
    cursor: pointer;
    a {
        display: block;
        height: 100%;
        white-space: nowrap;
        text-indent: 100%;
        overflow: hidden;
        &:hover {
            color: transparent;
        }
    }
}

.facebook {
    background-image: url(../img/facebook2.svg);
}

.twitter {
    background-image: url(../img/twitter.png);
}

.rss {
    background-image: url(../img/rss.svg);
}

.youtube {
    background-image: url(../img/youtube.svg);
}

.googleplus {
    background-image: url(../img/gplus.svg);
    &::before {
        content: "";
        background-image: url(../img/gplus-blue.svg);
    }
    &.icon-gray {
        background-image: url(../img/gplus-gray.svg);
        &:hover {
            background-image: url(../img/gplus-blue.svg);
        }
    }
}

#block_myaccount_infos {
    .myaccount-title a {
        color: $gray-darker;
    }
}

.pinterest {
    background-image: url(../img/pinterest.svg);
    &::before {
        content: "";
        background-image: url(../img/pinterest-blue.svg);
    }
    &.icon-gray {
        background-image: url(../img/pinterest-gray.svg);
        &:hover {
            background-image: url(../img/pinterest-blue.svg);
        }
    }
}

.vimeo {
    background-image: url(../img/vimeo.svg);
}

.instagram {
    background-image: url(../img/instagram2.svg);
}

.linkedin {
    background-image: url(../img/linkedin2.svg);
}

.footer-container {
    background-color: $gray-lightest;
    overflow: hidden;
    .h3 {
        margin-bottom: 37px;
        font: 700 17px $font-family;
    }
    .social-content {
        padding-top: 70px;
        @include media-breakpoint-down(md) {
            padding-top: 20px;
        }
    }
    li {
        padding-bottom: 15px;
        @include media-breakpoint-down(md) {}
    }
    li a {
        color: $gray-darker;
        cursor: pointer;
        font: 400 13px $font-family;
        &:hover {
            color: $brand-secondary;
        }
    }
    .links {
        padding-top: 70px;
        .collapse {
            display: inherit;
            margin-bottom: 0;
            img {
                margin-right: 13px;
            }
            p {
                color: $gray-darker;
                cursor: pointer;
                font: 400 13px $font-family;
                &:hover {
                    color: $brand-secondary;
                }
                a {
                    color: $gray-darker;
                    cursor: pointer;
                    font: 400 13px $font-family;
                    &:hover {
                        color: $brand-secondary;
                    }
                }
                .indent {
                    margin-left: 29px;
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding-top: 20px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.footer-logo {
    padding-right: 55px;
    img {
        width: 100%
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.row-after-footer {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1020px) {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
        display: block;
    }
}

.after-footer {
    border-top: 1px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 30px;
    @include media-breakpoint-down(sm) {
        border-top: none;
        margin-top: 0;
    }
    .anpc-logo {
        display: inline-flex;
        img {
            height: 50px;
        }
        @include media-breakpoint-down(sm) {
            justify-content: center;
            margin-top: 25px;
        }
        @media (max-width: 650px) {
            width: 100%;
        }
        @media (max-width: 532px) {
            flex-direction: column;
            align-items: center;
            div {
                padding-left: 0 !important;
                padding-bottom: 5px;
            }
        }
    }
    .pay-logos {
        padding-top: 12px;
        @media (max-width: 1020px) {
            padding-top: 0;
            display: flex;
            justify-content: end;
        }
        @media (max-width: 650px) {
            justify-content: center;
        }
        img {
            display: inline-block;
            vertical-align: middle;
            margin: 0 10px;
            height: auto;
        }
        .visa {
            max-width: 45px;
        }
        .mastercard {
            max-width: 27px;
        }
    }
    .copyright-box {
        @media (max-width: 650px) {
            text-align: center;
        }
    }
    .copyright {
        font: 400 13px/47px $font-family;
        color: #000;
    }
    .made {
        @media (max-width: 650px) {
            width: 100%;
        }
    }
    .made-by {
        display: inline-block;
        width: auto;
        text-align: center;
        @include media-breakpoint-down(sm) {
            padding-top: 10px;
        }
        a {
            display: inline-block;
            img {
                height: 33px;
                width: auto;
            }
        }
        @include media-breakpoint-down(md) {
            float: right;
        }
        @media (max-width: 650px) {
            float: none;
            display: block;
        }
        .senior-text {
            margin-top: 5px;
            font: 400 8.5px $font-family;
            color: #313131;
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .block_newsletter {
        padding-bottom: 0.625rem;
        border-bottom: 1px solid $gray-lighter;
    }
    .footer-container {
        box-shadow: none;
        margin-top: 0;
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .links {
            .h3 {
                line-height: 1.5;
                font-size: 1rem;
            }
            .title {
                padding: 5px 15px;
                border-bottom: 1px solid $gray-lighter;
                cursor: pointer;
                .collapse-icons .remove {
                    display: none;
                }
            }
            .title[aria-expanded="true"] .collapse-icons {
                .add {
                    display: none;
                }
                .remove {
                    display: block;
                }
            }
            .navbar-toggler {
                display: inline-block;
                padding: 0;
            }
            .collapse {
                display: none;
                &.in {
                    display: block;
                }
            }
            ul {
                background-color: #fff;
                margin-bottom: 0;
                >li {
                    padding: 10px 10px 10px 15px;
                    border-bottom: 1px solid $border-gray;
                    font-weight: bold;
                    a {
                        color: $gray-darker;
                    }
                }
            }
        }
        .navbar-toggler {
            width: 1.5rem;
        }
    }
    .copyright-container {
        p.copyright {
            margin-top: 5px;
        }
    }
}

@include media-breakpoint-down(md) {
    .block-social {
        text-align: center;
    }
}