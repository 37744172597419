#advanced_layered_navigation_filter {
    .filters-title {
        text-transform: uppercase;
        font: 600 14px $font-family;
        color: $gray;
        padding-bottom: 10px;
        border-bottom: 1px solid $brand-primary;
    }
    .af_subtitle_heading {
        display: inline-block;
        width: 100%;

        .af_subtitle {
            text-transform: none;
            font: 700 18px $font-family;
            color: #000;
            text-transform: initial;

            &:before {
                display: none;
            }
        }
    }
    .range-filter {
        .af_filter_content {
            width: 100%;
            .slider-container .back-bar .selected-bar {
                background-color: $brand-secondary;
            }
            .slider-container .back-bar .pointer {
                background: #fff;
                border: 2px solid $gray-light;
            }
            .slider .slider_value {
                background-color: $gray-light;
                border-radius: 5px;
                padding: 0 2px;
                span {
                    font-size: 16px;
                }
            }
        }
    }
    .af_filter_content {
        width: 100%;
        li {
            padding: 3px 0;
            &:hover {
                .name {
                    color: $brand-primary;
                }
            }
        }
    }
    .af_filter {
        border-bottom: none;
        margin-bottom: 30px;
        padding-bottom: 20px;
        background-color: #fff;
        border-bottom: 1px solid #d7d7d7;
        .name {
            font: 400 14px $font-family;
            color: #000;
        }
        .count {
            font: 400 14px $font-family;
        }
        label {
            margin-bottom: 0;
        }
        &:last-child{
            border-bottom: unset;
        }
        .toggle-cut-off.text-info{
            .more{
                i{
                    vertical-align: top;
                }
            }
            .less{
                i{
                    vertical-align: initial;
                }
            }
        }
    }
    .selectedFilters {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 15px;
        .clearAll {
            background-color: transparent;
            padding: 0;
            margin-top: 10px;
            margin-bottom: 10px;
            a {
                position: relative;
                background-color: $brand-primary;
                color: #fff;
                font: 400 13px $font-family;
                padding: 5px;
                padding-left: 32px;
                width: 100%;
                border-radius: 3px;
                &:hover {
                    background-color: darken($brand-secondary, 10%);
                }
                i {
                    font-size: 14px;
                    position: absolute;
                    left: 5px;
                    top: 2px;
                    @media (max-width: 575px){
                        top: 6px !important;
                    }
                }
            }
        }
        a {
            display: inline-block;
            padding: 0;
            i {
                font-size: 15px;
            }
        }
        > div {
            padding: 5px;
            background-color: $brand-primary;
            color: #fff;
            @include media-breakpoint-down(sm) {
                color: #000;
            }
        }
    }
    .af_filter.type-1 .af_filter_content, .af_filter.type-2 .af_filter_content {
        max-height: 290px;
    }

}

.advanced_layered_navigation_filter {
    z-index: 9;
    .filters-title{
        display: none;
    }
    &.filterToggle:not(.opened) > input[type="checkbox"] {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        position: relative;
        &.filterToggle > input[type="checkbox"] {
            display: block;
        }
        .block_content {
            display: block;
        }
        &.filterToggle {
            display: block;
            position: relative;
            top: 0;
            left: 0;
            -webkit-user-select: none;
            user-select: none;
            z-index: 9996;
            cursor: pointer;
            > input {
                display: block;
                width: 100% !important;
                height: 37px;
                position: absolute;
                right: 5px;
                top: -37px;
                cursor: pointer;
                opacity: 0;
                z-index: 9999;
                -webkit-touch-callout: none;
            }
            > input[checked="checked"] {
                position: fixed;
                top: 15px;
                right: 13px;
                width: 167px;
                height: 40px;
            }
            .filters_content {
                position: fixed;
                display: block;
                width: 100%;
                top: 0;
                left: 0;
                bottom: 0;
                padding: 20px 15px 15px 15px;
                background: #f7f7f7;
                list-style-type: none;
                -webkit-font-smoothing: antialiased;
                transform-origin: 0% 0%;
                transform: translate(-120%, 0);
                transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
                height: 100%;
                min-height: 500px;
                overflow: hidden;
                overflow-y: auto;
                z-index: 9997;

            }
        }
        &.filterToggle.opened .filters_content {
            transform: none;
        }
    }
}

.trigger-filter {
    font: 400 14px $font-family;
    padding: 6px !important;
    border: none;
    background-color: #fff;
    border: 1px solid $gray-light;
    text-align: center;
    color: $gray-darker;
    text-transform: none;
    width: 100%;
    cursor: pointer;
    box-shadow: none;
    border-radius: 3px;
}

.selectedFilters > div {
    background: #eee;
    padding: 5px 20px 5px 10px;
    border-radius: 3px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    font: 400 14px $font-family;
    color: #000;
}

.selectedFilters .clearAll {
    padding: 5px 5px 5px 5px;
    > a {
        position: static;
        padding: 0;
        color: #000;
    }
}

.selectedFilters a {
    color: #000;
    opacity: 1;
    i {

        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
        font-weight: 900;
    }
}

@include media-breakpoint-up(sm) {
    .selectedFilters .clearAll i {
        float: right;
        padding-top: 5px;
        padding-right: 14px;
    }
    #advanced_layered_navigation_filter {
        .block_content {
            display: inline-block;
            width: 100%;
        }
        .clearAll, .cf {
            z-index: 9;
        }
        .clearAll {
            margin-top: 0;
            margin-bottom: 0;
        }
        .cf {
            a {
                right: 5px;
                top: 5px;
                &:before {

                    color: #fff;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .show-prods, .prods-counter {
        display: inline-block;
        width: 49%;
        color: $gray-dark;
    }
    .show-prods {
        text-align: center;
        background-color: $brand-primary;
        color: #fff;
        padding: 5px;
        border-radius: 3px;
        margin-bottom: 10px;
    }
    .selectedFilters {
        overflow-x: scroll;
        white-space: nowrap;
        line-height: 45px;
        display: inline-block;
        width: 100%;
        div {
            display: inline;
            background-color: transparent;
            padding: 0;
            margin-right: 10px;
            &.hidden {
                display: none;
            }
        }
    }
    .advanced_layered_navigation_filter .af_filter .name {
        font-size: 14px;
    }
    .advanced_layered_navigation_filter .selectedFilters .clearAll {
        background-color: transparent;
        margin-top: 0;
        padding-top: 0;
        a {
            background-color: $gray-light;
            color: #000;
            border-radius: 3px;
            padding: 5px;
            padding-left: 30px;
        }
    }
    .advanced_layered_navigation_filter .selectedFilters a i {
        font-size: 14px;
    }
    .sort-by-row {
        display: inline-block;
        width: 100%;
    }
    .selectedFilters {
        .cf {
            a {
                top: 6px !important;
                right: 1px;
                &:before {

                    color: $gray !important;
                }
            }
        }
    }
    #js-product-list-top {
        .selectedFilters {
            .cf {
                vertical-align: 0px;
                a {
                    display: none;
                    top: -2px;
                    right: -10px;

                }
            }
        }
    }

    #advanced_layered_navigation_filter {
        .compact_block_content {
            padding: 10px 0px 0px 1px;
        }
        .selectedFilters > div {
            background: transparent;
            border: 1px solid $gray;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            display: inline-block;
            margin-top: 0;
            margin-bottom: 0;
            &.cf {
                padding: 5px 25px 5px 5px;
            }
        }
    }

    #advanced_layered_navigation_filter .selectedFilters .clearAll {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        a {
            padding: 4px 4px 5px 25px;
            background-color: transparent;
            color: #000;
            font-size: 15px;
            i {
                left: 1px;
                top: 2px;
            }
        }
    }
    .selectedFilters {
        padding-right: 15px;
        padding-left: 15px;
    }
}