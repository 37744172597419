.extracontent-box{
    background-color: $gray-lightest;
    h1{
        font: 700 48px $font-family;
        color: #000;
        text-transform: uppercase;
        padding-top: 50px;
        margin:auto
    }
    .extracontent-wrapper {
        margin:auto;
        .extra-img {
            text-align: center;
            img{
                padding: 50px;
                max-width: unset !important;
            }
        }
        .extra-text {
            text-align: center;
            padding-bottom: 80px;
            margin-left: 25px
        }
    }
}
.row.extracontent{
    margin: 0
}
