$container-max-widths: (
        xl: 1330px
        // xl: 1640px
);
$grid-gutter-widths: (
        xl: 14px,
        lg: 14px,
        md: 14px,
        sm: 14px,
        xs: 14px
);

$gray-darker: #595959;
$gray-dark: #3d3d3d;
$gray: #acacac;
$gray-light: #d6d7d8;
$gray-lighter: #d2d2d2;
$gray-lightest: #f5f5f5;
$gray-custom: #f0f0f0;
$gray-text: #313131;
$border-gray: #cccccc;
$brand-primary: #06a36b;
$brand-secondary: #939598;
$orange-light: #ffc34f;
$orange-lighter: #dfb240;
$green-lighter: #06a36b;
$brand-success: #48b62a;
$brand-warning: #e8c435;
$brand-danger: #e83535;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;
$font-size-h1: 1.375rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1.125rem;
$font-size-h5: 1rem;
$font-size-h6: 0.9375rem;
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$NotoSansPath: "~notosans-fontface/fonts";
$NotoSansVersion: "1.0.1";

$font-family: 'Red Hat Display', sans-serif;
$poppins: 'Red Hat Display', sans-serif;
$roboto: 'Red Hat Display', sans-serif;
$font-awesome: 'Font Awesome 5 Free';
$reduction: #d72f2f;