.ps-alert-error {
    margin-bottom: 0;
}
.ps-alert-error,
.ps-alert-success {
    margin-bottom: 0;
    .item {
        align-items: center;
        border: 2px $brand-danger solid;
        display: flex;
        background-color: $brand-danger;
        margin-bottom: 0;

        i {
            border: 15px $brand-danger solid;
            display: flex;

            svg {
                background-color: $brand-danger;
                width: 24px;
                height: 24px;
            }
        }

        p {
            background-color: $body-bg;
            margin: 0;
            padding: 18px 20px 18px 20px;
            width: 100%;
            overflow-wrap: break-word;
            overflow: hidden;
        }
    }
}

.ps-alert-success {
    .item {
        border-color: $brand-success;
        background-color: $brand-success;

        i {
            border-color: $brand-success;

            svg {
                background-color: $brand-success;
            }
        }
    }
}
