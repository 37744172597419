.sticky-menu{
    display: inline-block !important;
    cursor: pointer;

    font-size: 30px;
    vertical-align: -20px;
    @include media-breakpoint-down(md) {
        font-size: 25px;
        vertical-align: -14px;
    }
    &:hover {

         .menu{
            .top-menu{
                border: none;
                margin: 0;
                &[data-depth="0"]{
                    display: flex;
                }
                li{
                    text-align: center;
                    display: flex;
                    flex-grow:1;
                }
                li.category:hover{
                    .sub-menu{
                        display: block;
                    }
                }
                .sub-menu{
                    width: 234px;
                    min-width: 230px;
                    top: 30px;
                    z-index: 18;
                    left: 15px;
                    border: none;
                    background-color: rgb(13, 114, 109);
                    li.category{ 
                        font: 600 15px $font-family; 
                        text-align: left;            
                        &:hover {
                            .sub-menu2{
                                display: block;
                            }
                        }
                    }
                }
                .sub-menu2{
                    display: none;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 233px;
                    width: 887px;
                    height: 100%;
                    border: none;
                    overflow: hidden;
                    background-color: rgb(92, 230, 223);
                    li.category {
                        display: inline-block;
                        vertical-align: top;      
                        .collapse{
                            li{
                                display: block;
                            }
                        }
                    }
                    ul.top-menu[data-depth="2"]{
                        li.category{
                            width: 33%;
                            font: 600 12px $font-family;
                        }
                    }
                    ul.top-menu[data-depth="3"]{
                        li.category{
                            padding-left: 15px;
                            font: 600 10px $font-family;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    #_desktop_logo {
        padding-top: 10px;
    }
}

