.home-categories{
    @include media-breakpoint-up(md) {
        display: none;
    }
    .categories{
        .category{
            border-radius: 5px;
            margin-top: 10px;
            box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.04);
            .category-image{
                padding: 10px 5px;
                img{
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    border: 1px solid $gray-lightest;
                    border-radius: 5px;
                    width: 100%;
                    max-width: 98px;
                    max-height: 98px;
                }
            }
            .category-name{
                text-align: center;
                color: #000;
                height: 50px;
                padding: 0 3px 10px 3px;
                &:hover{
                    color: #000;
                }
            }
        }
    }
}

.wait-submit {
    display: none;
    position: fixed;
    z-index: 11111111;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background: rgba(0,0,0,.5);
    .wait-text {
      position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -200px;
      margin-left: -150px;
      img {
        max-width: 100%;
      }
    }
  }