@import "~bourbon/app/assets/stylesheets/bourbon";
@import "partials/_variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "partials/_mixins";
@import "partials/_fonts";
@import "partials/_commons";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/advancedsearchbar";
@import "components/account-expanded";
@import "components/favoriteproducts17";
@import "components/cart-expanded";
@import "components/new-menu";
@import "components/meniu";
@import "components/extracontent";
@import "components/blockscenes";
@import "components/newsletter";
@import "components/manufacturerbycategory";
@import "components/advancedlayerednavigation";
@import "components/productcomments";
@import "components/sticky-header";
@import "components/sticky-menu";
@import "components/sortare";
@import "components/manufacturer";
@import "components/tagimage";
@import "components/custommenu";
@import "components/modal-payment";
.table {
    color: $gray-darker;
    th {
        color: $gray-darker;
    }
}
.hidden {
    display: none;
}
.no-scroll {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.searchresult {
    -ms-overflow-style: none;
    overflow: auto;
}
:focus {
    outline: -webkit-focus-ring-color 0;
}
.input-group.focus {
    outline: transparent;
}
.form-control:focus {
    outline: transparent;
}
.align-self-center {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}
a,
button {
    &:active,
    &:visited,
    &:focus {
        text-decoration: none;
        outline: transparent !important;
    }
    &:focus {
        color: $brand-primary;
    }
}
button:focus {
    outline: none;
}
.modal-open {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
input:focus,
textarea:focus {
    -webkit-text-size-adjust: 100%;
}
.btn-primary {
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
}
.hidden-md-up {
    @include media-breakpoint-up(md) {
        display: none !important;
    }
}
select::-ms-expand,
input::-ms-expand {
    display: none;
}

select::-ms-clear,
input::-ms-clear {
    display: none!important;
    width: 0;
    height: 0;
}

select::-ms-expand {
    display: none;
}

.searchresult {
    -ms-overflow-style: none;
    overflow: auto;
}

:focus {
    outline: -webkit-focus-ring-color 0;
}

.input-group.focus {
    outline: transparent;
}

#category {
    #wrapper {
        padding-top: 0px;
    }
}

#header {
    background: white;
    color: $gray;
    .logo {
        max-width: 100%;
        width: 100%
    }
    a {
        &:hover {
            text-decoration: none;
            color: $brand-primary;
        }
    }
    .header-top {
        >.container {
            position: relative;
        }
        .header-wrapper {
            padding: 25px 0;
            padding-bottom: 17px;
            #_desktop_logo {
                @media (min-width: 1200px) {
                    width: 13.66667%;
                }
                img {
                    max-width: 100%;
                }
            }
            @include media-breakpoint-down(md) {
                padding: 15px 0;
            }
            @include media-breakpoint-down(sm) {
                padding-bottom: 30px;
            }
            .header-container {
                position: relative;
                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    .row:first-child {
                        width: 100%;
                    }
                }
                @include media-breakpoint-down(sm) {
                    display: block;
                    height:43px;
                    .row:first-child {
                        width: unset
                    }
                }
            }
        }
        .menu {
            >ul {
                >li {
                    float: left;
                }
            }
        }
        .position-static {
            position: static;
        }
        a[data-depth="0"] {
            color: $gray;
            text-transform: uppercase;
        }
        .right-nav {
            padding-left: 60px;
            @media (max-width: 1254px) {
                padding-left: 45px;
            }
            @include media-breakpoint-down(lg) {
                float: right;
                padding: 0 7px;
            }
        }
        .middle-nav.search_container_mob {
            width: 53%;
            @include media-breakpoint-down(md) {
                width: 41.66667%;
            }
        }
        h1 {
            margin-bottom: 0;
        }
    }
    .top-menu-link {
        margin-left: $medium-space;
    }
    #_desktop_language_selector {
        .language-selector {
            img {
                max-height: 18px;
            }
            span {
                text-transform: uppercase;
                color: #000;
                font: 400 13px $font-family;
                vertical-align: middle;
                &:hover {
                    color: #000;
                }
            }
            i {
                vertical-align: middle;
                &:after {
                    content: '';
                    background-image: url("../img/arrow-down-menu.png");
                    background-repeat: no-repeat;
                    height: 8px;
                    width: 11px;
                    display: inline-block;
                }
            }
        }
    }
    #_mobile_currency_selector,
    #_mobile_language_selector {
        .currency-selector,
        .language-selector {
            .dropdown-backdrop {
                opacity: unset;
                background-color: unset;
            }
        }
    }
    #_desktop_language_selector,
    #_desktop_currency_selector,
    #_mobile_currency_selector,
    #_mobile_language_selector {
        .dropdown-menu {
            min-width: unset;
            @include media-breakpoint-down(sm) {
                padding-left: 3px;
            }
        }
    }
    #_desktop_currency_selector {
        margin-left: 10px;
        .currency-selector {
            span {
                color: #000;
                font: 400 13px $font-family;
                &:hover {
                    color: #000;
                }
            }
            i {
                &:after {
                    content: '';
                    background-image: url("../img/arrow-down-menu.png");
                    background-repeat: no-repeat;
                    height: 8px;
                    width: 11px;
                    display: inline-block;
                }
            }
            .expand-more {
                color: #000;
                &:hover {
                    color: #000;
                }
            }
        }
        .dropdown:hover {
            .expand-more {
                color: #000;
            }
        }
    }
    #_mobile_currency_selector {
        .currency-selector {
            margin-left: 10px;
            .expand-more {
                @include media-breakpoint-down(xs) {
                    font-size: 13px;
                    vertical-align: middle;
                }
            }
            @include media-breakpoint-down(sm) {
                margin-left: 20px;
            }
            @include media-breakpoint-down(xs) {
                margin-left: 5px;
            }
        }
    }
    #_desktop_user_favorites {
        .favorites-header {
            .favorites-count-header {
                position: relative;
                .favorites-products-count {
                    font: 400 12px $font-family;
                    background-color: $orange-lighter;
                    padding-top: 1px;
                    text-align: center;
                    position: absolute;
                    top: -17px;
                    right: -29px;
                    height: 19px;
                    min-width: 19px;
                    border-radius: 50%;
                    display: block;
                    color: #fff!important;
                }
            }
        }
    }
    #_desktop_cart {
        .cart-header {
            position: relative;
            .product-count-header {
                position: absolute;
                left: 26%;
                bottom: 19px;
            }
        }
    }
    #_desktop_user_info,
    #_desktop_user_favorites,
    #_desktop_cart {
        display: flex;
        position: relative;
        a {
            color: #000;
        }
        span {
            font: 400 14px $font-family;
            color: #000;
            vertical-align: middle;
            &.header-cart-total {
                font: 400 14px $font-family;
            }
        }
        .cart-products-count {
            font: 400 12px $font-family;
            background-color: $green-lighter;
            padding-top: 1px;
            text-align: center;
            position: absolute;
            top: -17px;
            right: -19px;
            height: 19px;
            min-width: 19px;
            border-radius: 50%;
            display: block;
            color: #fff!important;
        }
    }
    #header-right {
        float: right;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: flex-end;
        padding-top: 12px;
    }
    .header-wrapper {
        .header-icon:before {
            display: inline-block;
            background-repeat: no-repeat;
            content: "";
            vertical-align: middle;
        }
        .user-icon {
            &:before {
                width: 25px;
                height: 20px;
                background: url("../img/user1.svg");
                background-repeat: no-repeat;
                background-size: auto 22px;
            }
        }
        .fav-icon {
            &:before {
                width: 25px;
                height: 20px;
                background: url("../img/heart1.svg");
                background-repeat: no-repeat;
                background-size: auto 22px;
            }
        }
        .cart-icon {
            &:before {
                width: 25px;
                height: 20px;
                background: url("../img/cart1.svg");
                background-repeat: no-repeat;
                background-size: auto 22px;
            }
            @include media-breakpoint-down(lg) {
                padding-right: 0;
            }
        }
        #_mobile_cart,
        #_mobile_user_favorites,
        #_mobile_user_info {
            a {
                padding: 10px 5px 10px 10px;
            }
        }
        .header-cart-w {
            display: inline-block;
            vertical-align: middle;
        }
        .fa-angle-down {
            margin-left: 5px;
        }
    }
}

.popover {
    font-family: inherit;
}

.img-content {
    visibility: hidden;
}

.page-footer {
    margin-bottom: 20px;
}

.hp-banner {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.mobile-banner {
    @include media-breakpoint-up(sm) {
        display: none;
    }
}
#category,
#prices-drop,
#search {
    #header {
        box-shadow: unset
    }
    #products {
        article {
            .product-name-variant {
                min-height: 49px;
                max-height: 49px;
                @include media-breakpoint-down(md) {
                    min-height: 43px;
                    max-height: 43px;
                }
                @media( max-width: 911px) {
                    min-height: 54px;
                    max-height: 54px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 44px;
                    max-height: 44px;
                }
                @media( max-width: 717px) {
                    min-height: 54px;
                    max-height: 54px;
                }
                @include media-breakpoint-down(xs) {
                    min-height: 50px;
                    max-height: 50px;
                }
                @media( max-width: 435px) {
                    min-height: 52px;
                    max-height: 52px;
                }
                @media( max-width: 381px) {
                    min-height: 63px;
                    max-height: 63px;
                }
                @media( max-width: 360px) {
                    min-height: 52px;
                    max-height: 52px;
                }
                .product-attributes {
                    font: 400 14px $font-family;
                }
            }
            .product-attributes {
                font: 400 12px $font-family !important;
            }
            .price {
                margin-left: 5px;
                @media (max-width: 1145px) {
                    margin-left: 5px;
                }
                @media (max-width: 400) {
                    margin-left: 0;
                }
            }
            .unity {
                font: 500 12px $font-family;
                @media (max-width: 1118px) {
                    margin-left: 5px;
                }
            }
        }
    }
}
#search {
    #products {
        article {
            .unity {
                font: 500 13px $font-family;
            }
            .product-name-variant {
                @include media-breakpoint-down(md) {
                    min-height: 53px;
                    max-height: 53px;
                }
                @include media-breakpoint-down(sm) {
                    min-height: 40px;
                    max-height: 40px;
                }
                @media( max-width: 717px) {
                    min-height: 54px;
                    max-height: 54px;
                }
                @include media-breakpoint-down(xs) {
                    min-height: 39px;
                    max-height: 39px;
                }
                @media( max-width: 449px) {
                    min-height: 52px;
                    max-height: 52px;
                }
                @media( max-width: 378px) {
                    min-height: 58px;
                    max-height: 58px;
                }
                @media( max-width: 364px) {
                    min-height: 55px;
                    max-height: 55px;
                }
            }
        }
    }
}

#wrapper {
    .banner {
        display: block;
        img {
            box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
        }
    }
    .breadcrumb {
        background: transparent;
        padding: 20px 0;
        margin-bottom: 0;
        &[data-depth="1"] {
            display: none;
        }
        ol {
            padding-left: 0;
            margin-bottom: 0;
        }
        li {
            display: inline;
            &::after {
                content: ">";
                color: $gray-darker;
                margin: 0.5rem;
            }
            &:last-child {
                content: ">";
                color: $brand-primary;
                margin: 0;
                &::after {
                    content: "";
                }
                a {
                    color: $brand-secondary;
                    font: 400 14px $font-family;
                }
            }
            a {
                color: #000;
                font: 400 14px $font-family;
            }
        }
    }
}

#product {
    #wrapper {
        background-color: #fff;
        .breadcrumb {
            padding: 0 0 35px 0;
        }
        .newsletter-wrapper {
            margin: 0;
        }
        .newsletter-wrapper2 {
            padding: 25px 0;
        }
    }
}

#index {
    #wrapper {
        padding-top: 0;
    }
}
#main {
    .page-header {
        margin-bottom: 1.563rem;
    }
    .page-content {
        margin-bottom: 1.563rem;
        h6 {
            margin-bottom: 1.125rem;
        }
        #notifications {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .page-footer {
        margin-bottom: 1.563rem;
    }
}

.productsliders_block {
    .nav-title-wrapper {
        .slider-title {
            font: 700 28px $font-family;
            color: #000;
            text-transform: unset !important;
            text-align: center;
            position: relative;
            margin: 0 -11px;
            .view-all {
                position: absolute;
                top: 0;
                right: 0;
                @include media-breakpoint-down(sm) {
                    position: unset;
                    display: block;
                    padding: 0;
                }
            }
        }
    }
    .slider-container {
        position: relative;
        z-index: 9;
        .slick-list {
            padding: 30px 0 !important;
        }
        .slick-arrow {
            z-index: 9;
            background: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            right: -18px;
            @media (max-width: 1374px) {
                right: 13px;
            }
            &:before {
                content: '';
                display: block;
                background-image: url("../img/arrow-slider-right.png");
                background-repeat: no-repeat;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 21px;
                top: 16px;
            }
            &.slick-prev {
                z-index: 9;
                background: #fff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                left: -14px;
                @media (max-width: 1374px) {
                    left: 14px;
                }
                &:before {
                    display: block;
                    background-image: url("../img/arrow-slider-left.png");
                    background-repeat: no-repeat;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 19px;
                    top: 15px;
                }
            }
        }
        .thumbnail-container {
            margin-bottom: 0;
            padding: 0 10px;
            @include media-breakpoint-down(md) {
                padding: 0 7px;
            }
            @include media-breakpoint-down(sm) {
                padding: 0 4px;
            }
        }
    }
    .view-all {
        display: flex;
        justify-content: flex-end;
        padding: 71px 0 5px 0;
        a {
            font: 400 16px/18px $font-family;
            color: #222222;
            white-space: nowrap;
            &:hover {
                color: $brand-primary;
            }
        }
    }
}

.product-description {
    h3 {
        overflow: visible !important;
        margin: 0;
        padding: 0;
        a {
            text-align: left;
            display: flex;
            justify-content: center;
            color: #000;
            font-weight: 700;
            margin-right: 3px;
            @include media-breakpoint-down(sm) {
                margin-right: -5px;
            }
        }
    }
}

.availability-listing {
    text-align: center;
    font: 400 15px $font-family;
    .product-available {
        color: $brand-primary
    }
    .product-unavailable {
        color: $brand-warning;
    }
    .product-last-items {
        color: #dfb240;
    }
}

.carousel-indicators .active {
    background-color: $gray-dark;
}

.carousel-indicators {
    z-index: 8 !important;
}

.carousel-indicators li {
    border: 1px solid $gray-dark;
    i {
        display: block;
    }
}

#notifications {
    ul {
        margin-bottom: 0;
    }
}

#search {
    .filter-button {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        #js-product-list-header {
            padding-left: 5px;
        }
        .products-selection .sort-by-row .products-sort-order {
            padding-left: 0;
        }
    }
}

.blockreassurance-title {
    font: 700 28px $font-family;
    color: #000;
    text-transform: unset !important;
    text-align: center;
    position: relative;
    margin: 0 -11px;
    padding: 40px 0 35px 0;
    .view-all {
        position: absolute;
        top: 0;
        right: 0;
        @include media-breakpoint-down(sm) {
            position: unset;
            display: block;
            padding: 0;
        }
    }
}
@include media-breakpoint-down(md) {
    .slick-dotted.slick-slider {
        margin-bottom: 0!important;
    }
    #header .header-top {
        .right-nav {
            padding-left: 15px;
        }
        #_mobile_logo {
            max-width: 25%;
        }
    }
    .container {
        max-width: 100%;
    }
    #products .product-miniature,
    .featured-products .product-miniature {
        padding: 0 5px;
    }
    .sub-menu {
        left: 0;
        min-width: 100%;
    }
    #blockcart-modal .product-image {
        width: 100%;
        display: block;
        max-width: 250px;
        margin: 0 auto 0.9375rem;
    }
    #blockcart-modal .cart-content {
        padding-left: 0;
    }
    #blockcart-modal .product-name,
    #product-availability {
        margin-top: $small-space;
    }
    #search_filters .facet .facet-label {
        text-align: left;
    }
    .block-category .category-cover {
        position: relative;
        text-align: center;
    }
    .block-category {
        padding-bottom: 0;
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .product-cover {
        .slick-dots {
            bottom: -25px;
        }
    }
    .hide-sm {
        display: none;
    }
    #header .header-top {
        .right-nav {
            padding-top: 0;
        }
        .middle-nav {
            padding-top: 0;
        }
    }
    .icons-wrapper {
        display: inline-block;
        float: right;
        padding-top: 15px;
        span {
            font: 700 15px $font-family;
            text-transform: uppercase;
            &.header-cart-total {
                font: 400 14px $font-family;
            }
        }
        .user-info {
            margin-left: 20px;
        }
        .cart-header {
            position: relative;
            margin-left: 20px;
            .cart-products-count {
                font: 400 12px $font-family;
                background-color: $green-lighter;
                padding-top: 1px;
                text-align: center;
                position: absolute;
                top: -16px;
                right: -7px;
                height: 19px;
                min-width: 19px;
                border-radius: 50%;
                display: block;
                color: #fff!important;
            }
        }
        .favorites-header {
            position: relative;
            margin-left: 20px;
            .favorites-products-count {
                font: 400 12px $font-family;
                background-color: $orange-lighter;
                padding-top: 1px;
                text-align: center;
                position: absolute;
                top: -15px;
                right: -6px;
                height: 19px;
                min-width: 19px;
                border-radius: 50%;
                display: block;
                color: #fff!important;
            }
        }
    }
    #header .menu-icon {
        padding-top: 10px;
        padding-right: 10px;
    }
    #wrapper {
        box-shadow: none;
        background-color: #fff
    }
    #checkout-cart-summary {
        float: none;
        width: 100%;
        margin-top: 1rem;
    }
    #header {
        background: #fff;
        .header-nav {
            background: white;
            margin-bottom: 0.625rem;
            color: $gray-darker;
            .top-logo {
                line-height: 50px;
                vertical-align: middle;
                width: 200px;
                margin: 0 auto;
                a {
                    img {
                        max-height: 50px;
                        max-width: 100%;
                    }
                }
            }
            .right-nav {
                flex-direction: column;
            }
            .user-info {
                text-align: left;
                margin-left: 0;
                .logged {
                    color: $brand-primary;
                }
            }
            .blockcart {
                margin-left: 0;
                background: inherit;
                &.active {
                    margin-left: 0.5rem;
                }
                &.inactive {
                    .cart-products-count {
                        display: none;
                    }
                }
            }
        }
        .header-top {
            padding-bottom: 0;
            a[data-depth="0"] {
                color: $gray-darker;
            }
        }
    }
    section.checkout-step {
        width: 100%;
    }
    .default-input {
        min-width: 100%;
    }
    label {
        clear: both;
    }
    .block-contact {
        padding-left: 0.9375rem;
        border: none;
    }
    .menu,
    .dropdown-item {
        padding-left: 0;
    }
    #manufacturer {
        h1 {
            padding-left: 5px;
        }
        .filter-button {
            display: none;
        }
        .products-selection .sort-by-row .products-sort-order {
            padding-left: 0;
        }
    }
}

#header .header-wrapper #_mobile_cart a,
#header .header-wrapper #_mobile_user_favorites a,
#header .header-wrapper #_mobile_user_info a {
    padding: 0;
}

@include media-breakpoint-down(xs) {
    #left-column,
    #content-wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    #header .header-top #_mobile_logo {
        max-width: 27%;
    }
    .icons-wrapper {
        padding-top: 7px;
        .user-info,
        .cart-header,
        .favorites-header {
            margin-left: 2px;
        }
    }
    #header .menu-icon {
        padding-top: 7px;
    }
    #contact {
        #left-column {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}

#header .header-top #_mobile_logo {
    @media (max-width: 425px) {
        margin-top: 3px;
    }
    @media (max-width: 320px) {
        margin-top: 2px;
    }
}

#wrapper {
    hr.border-set {
        margin: 0;
        margin-bottom: 30px;
    }
}

#address,
#checkout {
    .form-control-valign {
        .custom-radio {
            margin-right: 0;
        }
        .radio-inline {
            margin-right: 10px;
        }
    }
}

.thumbnail-container {
    &:hover {
        .cover-image {
            display: none;
        }
        .second-image {
            display: block;
        }
    }
}

.second-image {
    display: none;
}

#contact {
    .contact-form {
        input[type="file"] {
            width: 100%;
        }
    }
}

#_desktop_user_info #account-expanded .account-middle {
    a {
        i {
            margin-bottom: 8px;
        }
    }
}

.page-cms-about-us {
    padding: 0 !important;
    margin: 0;
    .banner {
        margin-top: 0;
    }
    .title-about-us {
        color: #000;
        text-transform: uppercase;
        font: 900 25px $font-family;
        margin: 25px 0;
    }
    .row.about-us-first,
    .row.about-us {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            display: block;
        }
        .about {
            padding-right: 60px;
            @include media-breakpoint-down(sm) {
                padding-right: 0px;
            }
        }
        .history {
            padding-left: 60px;
            @include media-breakpoint-down(sm) {
                padding-left: 0px;
                margin-top: 25px;
            }
        }
    }
    .row.about-us-first {
        margin-bottom: 30px;
    }
    .align-center {
        display: flex;
        align-items: center;
    }
    .banner-top {
        .banner .contents {
            height: 490px;
        }
    }
    .banner-bottom {
        .banner .contents {
            height: 402px;
        }
    }
    h1,
    h2,
    h3 {
        color: #000;
    }
    p {
        text-align: left;
        letter-spacing: 0px;
        color: #848484;
        opacity: 1;
    }
    .img-col {
        img {
            width: 100%;
        }
        .banner .contents {
            height: 300px;
        }
    }
    .section-bottom {
        margin-top: 30px;
        margin-bottom: 30px;
        h1 {
            margin-bottom: 50px;
            text-transform: uppercase;
        }
    }
    .icon-about-us {
        display: flex;
        justify-content: center;
    }
    .section-bottom {
        img {
            height: unset !important;
            max-height: 55px !important;
            margin-bottom: 25px;
        }
    }
}

.cui-search {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0.5em;
    cursor: pointer;
}

.hp-slider .slick-arrow.slick-prev {
    left: 5px;
}
.hp-slider .slick-arrow {
    right:5px;
}