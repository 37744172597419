.valid-modal,
.invalid-modal {
    background-color: rgba(255, 255, 255, 0.5);
    .modal-content {
        padding: 50px 0;
    }
    .modal-header {
        position: relative;
        border-bottom: unset;
        .modal-title {
            text-align: center;
            color: #000;
            font-size: 18px;
        }
        button.close {
            position: absolute;
            top: -30px;
            right: 15px;
        }
    }
    .modal-body {
        text-align: center;
        p {
            margin-bottom: 0;
            color: #000;
        }
        .block_newsletter {
            .newsletter-image {
                background-color: #fff !important;
                width: unset !important;
                height: unset !important;
                img {
                    display: none;
                }
                .form-container {
                    padding: 0;
                    p {
                        display: none;
                    }
                    .input-wrapper {
                        input[name="email"] {
                            border: 1px solid #313131;
                        }
                        @include media-breakpoint-down(xs) {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                            height: unset;
                            input[name="submitNewsletter"] {
                                margin-top: 5px;
                                position: unset;
                            }
                            .newsletter_tooltip.warning_inline {
                                margin-top: 5px;
                                position: unset;
                                ul {
                                    margin-bottom: 0;
                                    li {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                padding-bottom: 0;
                border-bottom: unset;
            }
        }
        .button-action {
            margin-top: 10px;
            button {
                border-radius: 5px;
            }
            .pay {
                border-radius: 5px;
                background-color: #06a36b;
                color: #fff;
            }
        }
    }
    .modal-footer {
        border-top: unset;
    }
}