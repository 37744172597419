#products,
.featured-products,
.product-accessories,
.product-miniature {
    .product-thumbnail {
        display: block;
        padding: 60px 60px 5px 60px;
        overflow: hidden;
        @media (max-width: 577px) {
            padding: 30px 30px 5px 30px;
        }
        @media (max-width: 450px) {
            padding: 30px 10px 5px 10px;
        }
        img {
            text-align: center;
            margin: 0 auto;
            width: auto;
            max-width: 100%;
            display: block;
        }
    }
    .thumbnail-container {
        padding: 0 20px;
        position: relative;
        margin-bottom: 2px;
        background-color: $gray-lightest;
        @include media-breakpoint-down(sm) {
            margin-bottom: 10px;
        }
    }
    .products-section-title {
        text-align: center;
        margin-bottom: 1.5rem;
    }
    .product-name-variant {
        min-height: 65px;
        max-height: 65px;
    }
    .product-title {
        text-transform: none;
        display: flex;
        width: 100%;
        margin-bottom: 0;
        margin-top: 5px;
        overflow: hidden;
        a {
            font: 500 18px $font-family;
            color: #000;
            text-decoration: none;
            text-align: center;
            width: 100%;
            @media (max-width: 577px) {
                font: 500 15px $font-family
            }
        }
    }
    .product-price-and-shipping {
        color: $gray-darker;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-bottom: 10px;
        @media (max-width: 460px) {
            flex-direction: column;
            align-items: center;
            height: 52px;
        }
        .price_tax_exc {
            font-size: 14px;
            font-weight: 500;
        }
    }
    .view-details {
        display: none;
    }
    .variant-links {
        position: relative;
        text-align: center;
        width: 100%;
        top: -0.25em;
        padding-top: 0.1875rem;
        min-height: 2.5rem;
        background: white;
    }
    img {
        width: 100%;
    }
    .product-miniature {
        .discount {
            display: none;
        }
    }
    .text-price-attributes {
        font: 500 20px $font-family;
        color: $brand-primary;
    }
    .unity {
        margin-left: 7px;
        margin-top: 2px;
        color: #000;
        font: 700 15px $font-family;
        @media (max-width: 844px) {
            margin-left: 5px;
        }
    }
    .product-flags {
        .product-flag,
        .flag-percentage,
        .flag-amount {
            display: flex;
            position: relative;
            color: #fff;
            text-transform: uppercase;
            width: 57px;
            height: 25px;
            font: 700 15px $font-family;
            box-shadow: none;
            margin-bottom: 5px;
            @include media-breakpoint-down(sm) {
                left: 6px;
            }
            &.discount,
            &.pack {
                display: none;
            }
        }
        .new {
            order: 0;
            color: #000;
            background: #fff;
            padding-top: 2px;
            padding-left: 10px;
        }
        .reduction {
            display: block;
            order: 1;
            background-color: $reduction;
            text-align: center;
            padding-top: 2px;
        }
        .amount {
            order: 2;
            display: block;
            background-color: $reduction;
            text-align: center;
        }
    }
    .comments_note {
        text-align: center;
        color: $gray;
    }
    .regular-price {
        color: $gray;
        text-decoration: line-through;
        display: block;
        font: 500 20px $font-family;
        // margin-left: 15px;
        @media (max-width: 920px) {
            font: 500 18px $font-family;
        }
        @media (max-width: 560px) {
            font: 500 16px $font-family;
        }
    }
    .price {
        font: 500 20px $font-family;
        color: $brand-primary;
        margin-left: 5px;
        @media (max-width: 920px) {
            font: 500 18px $font-family;
        }
        @media (max-width: 874px) {
            margin-left: 5px;
        }
        @media (max-width: 853px) {
            margin-left: 5px;
        }
        @media (max-width: 560px) {
            font: 500 16px $font-family;
            .price_tax_exc {
                font-size: 12px;
            }
        }
    }
    .empty-space {
        height: 15px;
    }
    .count {
        color: $gray;
        font-weight: 700;
        position: relative;
        bottom: 0.5rem;
    }
    .all-product-link {
        clear: both;
        color: $gray;
        font-weight: 700;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .add-to-cart-listing {
        text-align: center;
        .choose_variant {
            background-color: #000;
            color: #fff;
            border-radius: 25px;
            font: 400 14px $font-family;
            text-transform: uppercase;
            padding: 0.5rem 0.5rem;
            &:hover {
                background-color: $brand-primary;
            }
        }
    }
    .product-attributes {
        text-align: center;
        font: 400 16px $font-family;
    }
    .add-to-cart-listing-btn {
        text-transform: none;
        position: relative;
        padding: 4.5px 20px 5px 55px;
        border-radius: 5px;
        box-shadow: none;
        font: 700 14px $font-family;
        margin-bottom: 15px;
        margin-top: 5px;
        span {
            display: inline-block;
            margin-right: 9px;
            height: 30px;
            width: 42px;
            overflow: hidden;
            vertical-align: middle;
            position: absolute;
            left: -1px;
            top: -1px;

            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background-image: url("../img/shape22.png");
            background-repeat: no-repeat;
        }
        i:before {
            content: "";
            background-image: url("../img/dark-shop-cart.png");
            background-repeat: no-repeat;
            display: inline-block;
            width: 26px;
            height: 20px;
            vertical-align: -11px;
        }
        @include media-breakpoint-down(lg) {
            padding: 4.5px 13px 5px 55px;
        }
        @include media-breakpoint-down(md) {
            padding: 4.5px 20px 5px 55px;
        }
        @include media-breakpoint-down(sm) {
            padding: 4.5px 5px 5px 45px;
        }
    }
}

.productsliders_block,
.product-accessories,
.product-category {
    .product-price-and-shipping {
        @media (max-width: 520px) {
            flex-direction: column;
            align-items: center;
            height: 52px;
        }
        .price_tax_exc {
            @media (max-width: 380px) {
                font-size: 11px;
            }
        }
        .price,
        .regular-price {
            display: flex;
            justify-content: center;
            align-items: center;
            align-items: center;
        }
    }
}

article.product-miniature {
    padding-right: 12px;
    padding-left: 12px;
    @media (max-width: 577px) {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.star_content,
.no-reviews {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    .material-icons,
    .fa-star {
        font-size: 18px;
        color: #e8c435;
        margin: 0;
        padding-right: 5px;
        display: inline-block;
        float: left;
        &.empty {
            color: #cacaca;
        }
    }
}

#product .prod-mid {
    padding-top: 50px;
    .comments_note {
        padding-bottom: 25px;
    }
    .star_content,
    .no-reviews {
        display: inline-block;
        .material-icons {
            padding-right: 0;
            width: 19px;
        }
    }
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
}

.featured-products {
    padding-top: 20px;
    padding-bottom: 40px;
    position: relative;
    .thumbnail-container {
        max-width: 198px;
    }
    .favourite-button {
        span {
            display: none;
        }
    }
    .slick-arrow {
        position: absolute;
        top: -54px;
        right: 15px;
        border: 3px solid #bababa;
        border-radius: 50%;
        padding: 13px;
        &:before {
            content: '\f04b';
            color: #bababa;
            line-height: unset;
            opacity: unset;
            position: absolute;
            right: 6px;
            top: 13px;
            font-family: $font-awesome;
            font-weight: 900;
            font-size: 12px;
        }
        &.slick-prev {
            right: 50px;
            left: unset;
            &:before {
                right: 9px;
                top: 12px;
                transform: rotate(180deg);
            }
        }
    }
    .thumbnail-container {
        margin-bottom: 0;
    }
    article {
        padding: 0 15px;
    }
    h2 {
        text-transform: none;
        font: 400 22px $font-family;
        color: #5d5d5d;
        padding-left: 15px;
        padding-bottom: 30px;
    }
}

#product {
    .product-accessories,
    .product-slider-accessories {
        .product-thumbnail {
            padding: 60px 60px 5px 60px;
            @media (max-width: 577px) {
                padding: 30px 30px 5px 30px;
            }
            @media (max-width: 450px) {
                padding: 30px 10px 5px 10px;
            }
        }

    }
}

#link-combinations {
    scroll-margin-top: 5rem;
}

@include media-breakpoint-down(sm) {
    #combinations-container table,
    #combinations-container thead,
    #combinations-container tbody,
    #combinations-container th,
    #combinations-container td,
    #combinations-container tr {
        display: block;
        border: none;
    }
    #combinations-container thead tr,
    #combinations-container .hidden {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    #combinations-container tr {
        border: 2px solid #d5d5d5;
        margin-bottom: 15px;
        border-radius: 5px;
    }
    #combinations-container tr>td:first-child {
        border-bottom: 2px solid $brand-primary;
        height: 75px;
        display: flex;
        align-items: center;
    }
    #combinations-container tr>td:first-child:before {
        border-bottom: 2px solid $brand-primary;
        height: 75px;
        display: flex;
        align-items: center;
    }
    #combinations-container tr>td:last-child {
        border-bottom: none;
    }
    #combinations-container tr>td:last-child:before {
        border-bottom: none;
    }
    #combinations-container td {
        border: none;
        border-bottom: 2px solid #d5d5d5;
        position: relative;
        margin-left: 40%;
        white-space: normal;
        display: flex;
        align-items: center;
        height: 45px;
        &:nth-child(even) {
            background-color: #eeeeee;
            &::before {
                background-color: #eeeeee;
            }
        }
        &.image-combination,
        &.table-add-to-cart {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    #combinations-container td.table-stock span {
        margin: 0 auto;
    }
    #combinations-container td.table-stock a {
        display: block;
    }
    #combinations-container td:before {
        position: absolute;
        top: 0;
        left: -67%;
        width: 67%;
        padding: 13px;
        height: 45px;
        display: block;
        white-space: nowrap;
        text-align: left;
        border-bottom: 2px solid #d5d5d5;
        color: #292626;
        border-right: 2px solid #d5d5d5;
        text-transform: capitalize;
        font: 700 13px $font-family;
    }
    #combinations-container td:before {
        content: attr(data-title);
    }
    .table-quantity .qty {
        justify-content: center !important;
    }
    .packaging {
        .radio-input {
            display: grid;
        }
        label {
            margin-bottom: 0;
        }
    }
    .alert-add-to-cart {
        div {
            left: 0;
            right: 0;
            position: absolute;
            top: 50px;
            z-index: 1;
            background: $gray-light;
            padding: 10px 5px;
            &:before {
                content: "";
                position: absolute;
                right: 50%;
                top: -10px;
                border-style: solid;
                border-width: 0 10px 10px 10px;
                border-color: transparent transparent $gray-light transparent;
                z-index: 999;
            }
        }
    }
    .table-responsive {
        overflow-x: unset;
    }
    .product-prices .product-discount {
        margin-top: 0 !important;
    }
}