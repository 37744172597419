
.product-tag-image{
    border-top: 1px solid $gray-lighter;
    border-bottom: 1px solid $gray-lighter;
    padding: 35px 0;
    margin-top: 20px;
    .tagimage{
        text-align: center;
        margin-bottom: 5px;
        .tag-name{
            color: #000 ;
            font: 400 15px $font-family;
        }
        img{
            margin-bottom: 5px;
        }
    
    }
}
