.contact-rich {
    color: $gray;
    margin-bottom: 2rem;
    word-wrap: break-word;
    h4 {
        text-transform: uppercase;
        color: $gray-darker;
        margin-bottom: 2rem;
    }
    .block {
        height: auto;
        overflow: hidden;
        .icon {
            float: left;
            padding-right: 5px;
            i {
                font-size: 2rem;
            }
        }
        .data {
            color: $gray-darker;
            font-size: $font-size-sm;
            width: auto;
            overflow: hidden;
            &.email {

            }
        }
    }
}
.contact-rich .block .icon img {
    max-height: 25px;
    width: auto;
}

.contact-form {
    background: white;
    padding: 1rem;
    color: $gray;
    width: 100%;
    h3 {
        text-transform: uppercase;
        color: $gray-darker;
    }
    .form-footer{
        .btn-primary{
            border-radius: 50px;
            font-weight: 400;
            &:hover{
                background-color: $brand-primary;
            }
        }
    }
}

.group-span-filestyle {
    label {
        line-height: 1.65;
    }
}