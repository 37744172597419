#header {
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
    background: #fff;
    padding-bottom: 0 !important;
    .logo {

    }
  }
}

#header {
  .sticky {
    #header-wrapper {
      margin-top: 10px;
      margin-bottom: 10px;
      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
    .customer-name {
      display: none;
    }
  }
}

#index, #category, #product, #cms {
  #wrapper {
    &.extra-padd {
      padding-top: 105px;
    }
    &.extra-padd2 {
      padding-top: 70px;
    }
  }
}

.remove-margin {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
