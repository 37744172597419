
.renew-password {
  .email {
    padding-bottom: 30px;
    text-align: center;
  }

  [type=submit] {
    margin-left: 15px;
  }
}
