.banner {
    margin-top: 35px;
    .contents {
        background-repeat: no-repeat;
        height: 245px;
        @include media-breakpoint-down(xs){
            height: unset;
        }
        position: relative;
        a.btn{
            font: 400 14px $font-family;
            background-color: #000;
            border-radius: 25px;
            color: #fff;
            padding: 8px 28px;
            text-transform: uppercase;
            @include media-breakpoint-down(xs){
                font: 400 10px $font-family;
                padding: 6px 20px;
            }
        }
    }
    .banner-link {
        display: none;
        position: absolute;
        color: #000;
        font: 400 14px $font-family;
        top: 15px;
        left: 15px;
        .fa-chevron-right {
            font-size: 8px;
            vertical-align: 1px;
        }
    }
    .description-banner {
        position: absolute;
        top: 68px;
        left: 65px;
        width: 300px;
        @include media-breakpoint-down(xs) {
            top: 45px;
            left: 16px;
        }
        @media (max-width: 350px) {
            width: 230px;
        }
        h1, h2, .title {
            font: 700 25px $font-family !important;
            color: #000;
            margin-bottom: 8px;
        }
        p {
            color: #000;
            font: 400 14px $font-family;
        }
    }
    .contents{
        .button-position{
            position: absolute;
            &.button-top-left{
                top: 30px;
                left: 60px;
                @include media-breakpoint-down(xs) {
                    top: 15px;
                    left: 16px;
                }
            }
            &.button-top-center{
                top: 30px;
                left: 50%;
                @include media-breakpoint-down(xs) {
                    top: 15px;
                    left: 50%;
                }
            }
            &.button-top-right{
                right: 60px;
                top: 30px;
                @include media-breakpoint-down(xs) {
                    top: 15px;
                    right: 16px;
                }
            }
            &.button-bottom-left{
                bottom: 30px;
                left: 60px;
                @include media-breakpoint-down(xs) {
                    bottom: 15px;
                    left: 16px;
                }
            }
            &.button-bottom-center{
                bottom: 30px;
                left: 50%;
                @include media-breakpoint-down(xs) {
                    bottom: 15px;
                    left: 50%;
                }
            }
            &.button-bottom-right{
                bottom: 30px;
                right: 60px;
                @include media-breakpoint-down(xs) {
                    bottom: 15px;
                    right: 16px;
                }
            }
            &.button-center{
                left: 45%;
                top: 41%;
                @include media-breakpoint-down(xs) {
                    left: 35%;
                    top: 50%;
                }
            }
            &.button-center-left{
                left: 60px;
                top: 43%;
                @include media-breakpoint-down(xs) {
                    left: 16px;
                    top: 50%;
                }
            }
            &.button-center-right{
                right: 60px;
                top: 43%;
                @include media-breakpoint-down(xs) {
                    right: 16px;
                    top: 50%;
                }
            }
        }
    }
}

.banner-category {
    @include media-breakpoint-down(xs){
        display: none;
    }
    .banner {
        margin-top: 0;
        .description-banner {
            top: 57px;
            p {
                font: 400 15px $font-family;
            }
        }
    }
    .contents {
        height: 187px;
    }
}
.banner-category-mobil{
    @include media-breakpoint-up(sm){
        display: none;
    }
    .banner {
        margin-top: 0;
        .description-banner {
            top: 25px;
        }
    }
}
.hp-slider{
    @include media-breakpoint-down(sm){
        display: none;
    }
    .banner{
        margin-top: 0;
        .contents{
            height: 492px;
            cursor: pointer;
            img.bg{
            
            }
            .description-banner{
                top: 33%;
                left: 12%;
            }
            .button-position{
                &.button-top-left{
                    top: 30px;
                    left: 12%;
                }
                &.button-top-center{
                    top: 30px;
                    left: 50%;
                }
                &.button-top-right{
                    right: 12%;
                    top: 30px;
                }
                &.button-bottom-left{
                    bottom: 30px;
                    left: 12%;
                }
                &.button-bottom-center{
                    bottom: 30px;
                    left: 50%;
                }
                &.button-bottom-right{
                    bottom: 30px;
                    right: 12%;
                }
                &.button-center{
                    left: 50%;
                    top: 46%;
                }
                &.button-center-left{
                    left: 12%;
                    top: 46%;
                    
                }
                &.button-center-right{
                    right: 12%;
                    top: 46%;
                }
            }
        }
    }
    .slick-arrow {
        @media (max-width: 1374px) {
            display: none !important;
        }
        z-index: 9;
        background: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        right: -27px;
        &:before {
            content: '';
            display: block;
            background-image: url("../img/arrow-slider-right.png");
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 21px;
            top: 16px;
        }
        &.slick-prev {
            z-index: 9;
            background: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            left: -25px;
            &:before {
                display: block;
                background-image: url("../img/arrow-slider-left.png");
                background-repeat: no-repeat;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 19px;
                top: 15px;
            }
        }
        &.slick-next {
            &:hover,
            &:focus {
                background: #fff;
            }
        }
    }
}
.hp-slider-mobil{
    overflow-x: hidden;
    @include media-breakpoint-up(md){
        display: none;
    }
    .banner {
        margin-top: 0;
        .contents{
            height: unset;
            .description-banner{
                @include media-breakpoint-down(xs){
                    top: 29%;
                    // top: 18%;
                    left: 16px;
                    h1, h2, .title{
                        font: 700 16px $font-family !important;
                        margin-bottom: 2px;
                    }
                    p{
                        font: 400 12px $font-family;
                        margin-bottom: 2px;
                    }
                }
            }
            .button-position{
                &.button-top-left{
                    top: 8px;
                    left: 4%;
                }
                &.button-top-center{
                    top: 8px;
                    left: 37%;
                }
                &.button-top-right{
                    right: 4%;
                    top: 8px;
                }
                &.button-bottom-left{
                    bottom: 8px;
                    left: 4%;
                }
                &.button-bottom-center{
                    bottom: 8px;
                    left: 37%;
                }
                &.button-bottom-right{
                    bottom: 8px;
                    right: 4%;
                }
                &.button-center{
                    left: 37%;
                    top: 46%;
                }
                &.button-center-left{
                    left: 4%;
                    top: 46%;
                    
                }
                &.button-center-right{
                    right: 4%;
                    top: 46%;
                }
            }
            
        }
    }
}
.slick-dots{
    bottom: 26px !important;
    @include media-breakpoint-down(xs){
        bottom: 5px !important;
    }
    button{
        &::before{
            content: '' !important;
        }
    }
}
.slick-dots .slick-active {
    background-color: $gray-dark;
}

.slick-dots {
    z-index: 8 !important;
}

.slick-dots li {
    border: 1px solid $gray-dark;
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
    i {
        display: block;
    }
}
.blog-group {
    .banner-title {
        h3 {
            font: 700 28px $font-family;
            padding: 30px 0 30px 0;
            color: #000;
            text-transform: unset !important;
            text-align: center;
        }
    }
    .blog {
        margin-top: 0;
    }
    .image-blog {
        width: 32%;
        display: inline-block;
        margin: 0px 11px;
        vertical-align: top;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        @media (max-width: 1301px) {
            margin: 0 9px;
        }
        @include media-breakpoint-down(lg) {
            width: 31.5%;
            margin: 0px 11px;
        }
        @include media-breakpoint-down(md) {
            margin: 0px 8px;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
        }
    }
    .contents {
        height: 325px;
        width: 100%;
        float: left;
        background-position: center;
        position: unset;
    }
    .description-banner {
        margin-top: 355px;
        position: unset;
        width: 100%;
        @include media-breakpoint-down(sm) {
            text-align: center;
        }
        h1, h2, .title {
            font: 700 15px $font-family !important;
            color: #000;
            margin-bottom: 8px;
        }
        .data {
            font: 400 13px $font-family;
            color: $gray-darker;
        }
        p {
            font: 400 16px $font-family;
            color: #000;
            overflow: hidden;
            -webkit-box-orient: vertical;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
        }
        p {
            a {
                font: 400 14px $font-family;
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 25px;
                color: #000;
                padding: 7px 28px 8px 27px;
                &:hover {
                    border: 1px solid $brand-primary;
                    color: $brand-primary;
                }
            }
        }
    }
}

.blog-carousel {
    .slider-title {
        padding: 30px 0 30px 0 !important;
    }
    .article-title {
        p {
            margin-top: 33px;
            margin-bottom: 8px;
            font: 700 15px $font-family;
            color: #000;
        }
    }
    .article-data {
        p {
            font: 400 13px $font-family;
            color: $gray-darker;
        }
    }
    p.description {
        font: 400 16px $font-family;
        color: #000;
        overflow: hidden;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
    }
    a.btn-blog {
        font: 400 14px $font-family;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 25px;
        color: #000;
        padding: 7px 28px 8px 27px;
        text-transform: uppercase;
        &:hover {
            border: 1px solid $brand-primary;
            color: $brand-primary;
        }
    }
    .pseudolink {
        cursor: pointer;
    }
    .slick-slide img {
        width: 100%;
    }
    .slick-arrow {
        @media (max-width: 1374px) {
            display: none !important;
        }
        z-index: 9;
        background: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        right: -18px;
        &:before {
            content: '';
            display: block;
            background-image: url("../img/arrow-slider-right.png");
            background-repeat: no-repeat;
            width: 50px;
            height: 50px;
            position: absolute;
            left: 21px;
            top: 16px;
        }
        &.slick-prev {
            z-index: 9;
            background: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            left: -14px;
            &:before {
                display: block;
                background-image: url("../img/arrow-slider-left.png");
                background-repeat: no-repeat;
                width: 50px;
                height: 50px;
                position: absolute;
                left: 19px;
                top: 15px;
            }
        }
        &.slick-next {
            &:hover,
            &:focus {
                background: #fff;
            }
        }
    }
}