#manufacturerbycategory {
    .manufacturer-title {
        text-transform: none;
        font: 400 22px "PT Sans", sans-serif;
        color: #5d5d5d;
    }
    .tabs {
        box-shadow: unset;
        margin-top: 25px;
        padding: 0;
        @include media-breakpoint-down(xs){
            margin-top: 10px;
        }
        .tab-pane {
            padding-top: 0;
            padding-bottom: 15px;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
        .nav-tabs {
            border-bottom: 1px solid $gray-lightest;
            margin: 0;
            .nav-link {
                border-top: 2px solid #fff;
                font: 400 16px $font-family;
                position: relative;
                padding: 15px;
                &:hover {
                    border-bottom: unset;
                }
            }
            .nav-link.active {
                border-top: 2px solid $brand-primary;
                border-bottom: 1px solid #fff;
                border-right: 1px solid $gray-lightest;
                box-shadow: 6px 6px 4px -1px rgba(220,220,220,0.23);
            }
            .nav-item {
                position: relative;
                margin: 0 0 -1px 0;
                @include media-breakpoint-down(sm) {
                    display: block;
                    width: 100%;
                }
            }
        }
        .tab-content {
            .brand-link {
                height: 140px;
                display: inline-block;
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    border-right: 1px solid $gray-lightest;
                    top: 19px;
                    right: 0;
                    height: 113px;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
                &:nth-of-type(5n+5) {
                    &:before {
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    border-right: 1px solid $gray-lightest;
                    bottom: -100px;
                    right: 112px;
                    height: 193px;
                    transform: rotate(90deg);
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
                &:nth-of-type(1n+6) {
                    &:after {
                        display: none;
                    }
                }
                img {
                    padding: 22px 39px;
                    @include media-breakpoint-down(sm) {
                        padding: 22px 1px;
                    }
                }
            }
        }
    }
}