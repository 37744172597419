#header {
    .header-top {
        .middle-nav {
            position: unset;
            @include media-breakpoint-down(sm) {
                position: fixed;
            }
        }
        .position-menu {
            position: unset;
            .menu-wrapper {
                @include media-breakpoint-down(lg) {
                    text-align: center !important;
                    float: unset !important;
                }
            }
        }
        .mainmenu {
            .menu {
                .top-menu[data-depth="0"] {
                    li.category {
                        display: inline-block;
                        a {
                            &:after {
                                content: "";
                                background: url("../img/arrow-down-menu.png") no-repeat;
                                width: 11px;
                                height: 8px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }
                    li.link,
                    li.cms {
                        display: inline-block;
                        a {
                            text-transform: uppercase;
                            background-color: #fff;
                            font: 500 15px $font-family;
                            color: #000;
                            min-width: unset;
                        }
                    }
                    a {
                        color: #000;
                        font: 500 15px $font-family;
                        position: relative;
                        padding: 14px 14px;
                        @media (max-width: 1254px) {
                            padding: 14px 11px;
                        }
                        @include media-breakpoint-down(lg) {
                            padding: 14px 7px;
                        }
                    }
                    .sub-menu {
                        display: none;
                        position: absolute;
                        margin-left: 0;
                        left: 0;
                        width: 100%;
                        background: #fff;
                        z-index: 16;
                        @include media-breakpoint-down(lg) {
                            top: 50px;
                        }
                        @include media-breakpoint-down(md) {
                            top: 80px;
                        }
                    }
                }
                .top-menu[data-depth="1"] {
                    display: flex;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    padding: 28px;
                    @include media-breakpoint-down(md) {
                        box-shadow: unset;
                    }
                    .menu-right {
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 20px 0;
                        @include media-breakpoint-down(md) {
                            display: block;
                            padding: 0;
                            margin: 0;
                            width: 100%;
                            margin-bottom: 22px;
                        }
                    }
                    li.category {
                        display: flex;
                        flex-direction: column;
                        width: 25%;
                        padding-bottom: 28px;
                        a {
                            white-space: normal;
                            text-align: left;
                            color: #000;
                            padding: 0;
                            padding-bottom: 17px;
                            font: 500 16px $font-family;
                            &:after {
                                content: "";
                                background-image: unset;
                            }
                            @include media-breakpoint-down(md) {
                                padding-bottom: 0;
                                padding-top: 10px;
                            }
                        }
                        &:hover {
                            >a {
                                color: $brand-primary;
                            }
                            .menu-img {
                                display: none;
                            }
                        }
                        @include media-breakpoint-down(md) {
                            display: block;
                            width: 100%;
                            padding: 0;
                            &:first-child {
                                padding-left: 0;
                            }
                            &:nth-child(4n+5) {
                                padding-left: 0;
                            }
                        }
                    }
                    .sub-menu2 {
                        @include media-breakpoint-down(md) {
                            left: 220px;
                            width: 470px;
                        }
                        .top-menu[data-depth="2"] {
                            li {
                                width: 100%;
                                @include media-breakpoint-down(md) {
                                    flex: 0 0 48%;
                                }
                                padding: 0;
                                padding-bottom: 10px;
                                a {
                                    text-transform: initial;
                                    padding-bottom: 0;
                                    font: 400 14px $font-family;
                                    color: $gray-darker;
                                    &:hover {
                                        color: $brand-primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu-images-container {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.menu-wrapper.only-mobile {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.nav-phone {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 9;
    a {
        font: 700 13px $font-family;
        color: $brand-secondary;
        &:before {
            content: "";
            background: url("../img/call.png") no-repeat;
            width: 20px;
            height: 14px;
            display: inline-block;
            vertical-align: -3px;
        }
    }
}

#index .hp-menu-btn {
    display: block;
    width: 100%;
    text-transform: uppercase;
    background-color: #0e6d86;
    @include border-radius(0);
    i {
        margin-right: 10px;
    }
}

#header .header-top .mainmenu .menu .top-menu[data-depth="0"] li.category:hover {
    .sub-menu {
        display: inline-block;
        padding-top: 15px;
        @include media-breakpoint-down(md) {
            padding-top: unset;
        }
    }
}

@include media-breakpoint-down(md) {
    #index #header .header-top .mainmenu .menu .top-menu[data-depth="0"] .sub-menu {
        display: inline-block;
    }
}

@include media-breakpoint-down(md) {
    #_mobile_search_trigger {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        .search-info {
            &:before {
                content: " ";
                width: 25px;
                height: 20px;
                display: inline-block;
                vertical-align: middle;
                background: url("../img/search1.svg");
                background-repeat: no-repeat;
                background-size: auto 22px;
            }
            i {
                font-size: 24px;
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    #_search_mobile {
        padding-right: 5px;
    }
}

#product {
    .product-sticky {
        display: none;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #fff;
        img {
            width: 50px;
        }
        .h1 {
            font-size: 18px;
            text-transform: none;
            margin-bottom: 0;
            margin-top: 10px;
            font-weight: 500;
            color: #000;
        }
        .discount-percentage,
        .control-label,
        .qty,
        .tax-shipping-delivery-label,
        .product-minimal-quantity {
            display: none !important;
        }
        .product-prices {
            margin-top: 10px;
            margin-bottom: 0;
            width: 100%;
            @media (max-width: 490px) {
                flex-direction: column;
                align-items: flex-start;
            }
            div {
                display: block;
                float: right;
                @include media-breakpoint-down(sm) {
                    float: left;
                }
                span {
                    float: right;
                    &.discount {
                        display: none;
                    }
                }
            }
            .product-discount {
                margin-bottom: 0;
                margin-top: 0;
            }
            .regular-price {
                margin-right: 15px;
                font-size: 14px;
                @media (max-width: 490px) {
                    margin-left: 0 !important;
                }
            }
            .product-price {
                margin-bottom: 0;
                span.price_tax_exc {
                    @media (max-width: 490px) {
                        float: unset;
                        margin-left: 0;
                        vertical-align: text-top;
                    }
                }
            }
            .current-price {
                margin-bottom: 0;
                margin-top: 10px;
                @media (max-width: 490px) {
                    margin-top: 0;
                }
                span {
                    font-size: 16px !important;
                }
            }
            .has-discount {
                .current-price {
                    margin-top: 0;
                }
            }
            span.price_tax_exc {
                font-size: 13px !important;
            }
        }
        .add {
            margin-bottom: 0;
        }
        &.sticky {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1111;
            right: 0;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.11);
        }
    }
}