#manufacturer {
  .manufacturer-name{
     @include media-breakpoint-down(sm){
       display: block;
       float: none;
     }
  }
}

.brands-carousel {
  padding: 25px 0;
  overflow: hidden;
  .swiper-slide{
    margin: auto;
    img {
      max-height: 50px;
      filter: grayscale(100%);
      opacity: 50%;
      &:hover {
        filter: unset;
        opacity: 100%;
      }
    }
  }

}

.swiper-button-prev-brands{
  &::before{
    content: '';
    background: url('../img/arrow-slider-left.png') ;
    background-repeat: no-repeat;
    height: 42px;
    width: 50px;
    display: inline-block;
  }
}
.swiper-button-next-brands{
  padding-left: 18px !important;
  &::before{
    content: '';
    background: url('../img/arrow-slider-right.png') ;
    background-repeat: no-repeat;
    height: 34px;
    width: 16px;
    display: inline-block;
  }

}
.swiper-button-next-brands, .swiper-button-prev-brands{
  background-image: unset !important;
  width: 43px !important;
  height: 41px !important;
  background: #fff;
  top: -10px !important;
  border-radius: 50%;
  padding-top: 11px;
  padding-left: 14px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.h3.slider-title{
  font: 700 28px $font-family;
  padding: 60px 0 5px 0;
  color: #000;
  text-transform: unset !important;
  text-align: center;
}

.brands-title {
  display: inline-block;
  color: #000;
  font-size: 48px;
  text-transform: uppercase;
  padding: 50px 0;
}
.manufacturer-name {
  padding-top: 6px;
  margin-bottom: 0;
  font: 600 18px $font-family;
}


li.brand {
  display: inline-block;
  height: 220px;
  margin-bottom: 15px;
  padding: 0 10px;
  text-align: left;

}
.brand-container {
  height: 100%;
  position: relative;
}
.brand-img, .brand-infos, .brand-products, .brand-details {
  text-align: center;
}
.brand-img {
  margin-top: 10px;
}
.brand-infos {
  overflow: hidden;
  height: 40px;
  margin-top: 10px;
  a {
    font: 600 14px $font-family;
  }
}

.brand-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: $brand-primary;
  cursor: pointer;
  a {
    color: #fff;
  }
  &:hover {
    background-color: darken($brand-primary, 10%);
  }
}

.brand-desc-container {
  padding-top: 20px;
}
.brands-name {
  font: 600 18px $font-family;
  color: $brand-primary;
  margin-top: 5px;
}
#manufacturer{
  #wrapper{
    div#content-wrapper{
      margin: 0 20px;
      .brand-container{
        background-color: #fff;
        .brand-details{
          a:focus{
            color: #fff;
          }
        }
      }
    }
    #content-wrapper{
      .brand{
        border: 1px solid $gray-lighter;
        height: 274px;
        text-align: center;
        margin-bottom: 15px;
        .brand-products{
          display: flex;
          justify-content: center;
          flex-direction: column;
          
        }
        .btn-theme0{
          color: #fff;
          padding: 8px;
          margin: 20px 30px;
          border-radius: 20px;
          background-color: $brand-primary;
          i{
            vertical-align: middle;
            margin-left: 10px;
          }
        }
      }
    }
  }
}