#products {
    color: $gray;
    background-color: #fff;
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
    .h1.cat-name {
        display: block !important;
        color: #000;
        font-size: 48px;
        margin-bottom: 52px;
        @media (max-width: 1070px) {
            font-size: 40px;
        }
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
    }
    .products-select {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }
    .up {
        margin-bottom: 1rem;
        .btn-secondary {
            color: $gray;
            text-transform: inherit;
            .material-icons {
                margin-right: 0;
            }
        }
    }
}

.block-category {
    margin-bottom: 1.563rem;
    #category-description {
        p,
        strong {
            font-weight: 400;
            color: $gray;
        }
        p {
            color: $gray-darker;
            margin-bottom: 0;
            &:first-child {
                margin-bottom: $medium-space;
            }
        }
    }
    .category-cover {
        position: absolute;
        right: 0.75rem;
        bottom: 0;
        img {
            width: 141px;
            height: 180px;
        }
    }
}

#search,
#manufacturer {
    .products-selection {
        top: 0;
    }
    #products {
        margin-top: 20px;
    }
}

#manufacturer {
    #js-product-list-top {
        display: none;
    }
}

.products-selection {
    .sort-by-row,
    .nbr-products {
        display: inline-block;
        font: 400 14px $font-family;
        color: #000;
        display: inline-block;
        vertical-align: top;
        padding-top: 7px;
        label {
            font: 400 14px $font-family;
            color: #000;
        }
    }
    .sort-by {
        white-space: normal;
        margin-right: 2px;
        margin-left: 25px;
        text-align: right;
        font: 400 14px $font-family;
        color: #000;
    }
    @include media-breakpoint-down(sm) {
        .sort-by-row,
        .filter-button {
            display: inline-block;
            padding-top: 0;
            vertical-align: middle;
            width: 49%;
        }
    }
    #af_nb_items {
        color: #000;
        background: none;
        cursor: pointer;
        border: 1px solid $border-gray;
        font: 400 14px $font-family;
        margin-left: 0;
        width: 55px;
        padding: 2px 7px;
    }
    h1 {
        padding-top: 0;
        text-transform: none;
        color: #222222;
    }
}

.af_pl_wrapper.af {
    .products-selection {
        margin-bottom: 20px;
    }
}

.products-selection {
    .title {
        color: $gray;
    }
    .sort-by-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .sort-by {
        white-space: normal;
        word-break: break-word;
        text-align: right;
        color: #000;
        font: 400 14px $font-family;
        padding-right: 10px;
    }
    .total-products {
        padding-top: 0.625rem;
    }
    h1 {
        padding-top: 0.625rem;
        color: #000;
        text-transform: initial;
        font: 700 25px $font-family;
    }
    @include media-breakpoint-down(sm) {
        position: relative;
        top: unset;
        right: unset;
        .sort-by-row {
            padding: 0 5px 15px 5px;
            .filter-button,
            .products-sort-order {
                flex: 0 0 50%;
                justify-content: space-between;
                .select-title,
                .trigger-filter {
                    border-radius: 3px;
                    padding: 6px;
                    i {
                        padding-left: 0;
                    }
                }
            }
            .filter-button {
                padding-right: 5px;
            }
            .products-sort-order {
                padding-left: 5px;
            }
        }
    }
}

.products-sort-order {
    color: $gray;
    .select-title {
        display: inline-block;
        width: 100%;
        color: #000;
        background: white;
        padding: 4px 10px 4px 10px;
        cursor: pointer;
        border: 1px solid $border-gray;
        font: 400 14px $font-family;
        i {
            font-size: 15px;
            padding-left: 13px;
        }
    }
    .select-list {
        display: block;
        color: $gray-darker;
        padding: $small-space $medium-space;
        &:hover {
            background: $brand-primary;
            color: white;
            text-decoration: none;
        }
    }
    .dropdown-menu {
        left: auto;
        width: 12.88rem;
        background: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
        margin: 0;
        position: absolute;
        top: 26px;
        right: 0;
        @include media-breakpoint-down(md) {
            right: 0;
            width: 170px;
            background-color: #fff;
        }
    }
}

.facet-dropdown {
    color: $gray;
    padding-left: 0;
    padding-right: 0;
    background: $gray-light;
    border: 3px solid transparent;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px 1px $gray-light;
    &.open {
        border: 0;
        >.select-title {
            border: 3px solid $brand-primary;
            background: $gray-lighter;
        }
    }
    .select-title {
        display: inline-flex;
        align-items: center;
        margin-left: 0;
        width: 100%;
        color: $gray-darker;
        background: $gray-light;
        padding: $small-space $small-space $small-space $medium-space;
        cursor: pointer;
        >i {
            margin-left: auto;
        }
    }
    .select-list {
        display: block;
        color: $gray-darker;
        background: $gray-lighter;
        padding: $small-space $medium-space;
        &:hover {
            background: $brand-primary;
            color: white;
            text-decoration: none;
        }
    }
    .dropdown-menu {
        padding: 0;
        margin-top: 3px;
        left: auto;
        width: 100%;
        background: $gray-lighter;
        border: none;
        box-shadow: 1px 1px 1px 1px $gray-light;
    }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
    @include box-shadow;
    margin-bottom: 1.563rem;
    background: white;
    padding: 1.563rem 1.25rem;
    .facet {
        .collapse {
            display: block;
        }
        padding-top: $small-space;
        .facet-title {
            color: $gray;
        }
        .facet-label {
            margin-bottom: 0;
            a {
                margin-top: 0.4375rem;
                color: $gray-darker;
                display: inline-block;
                font-size: $font-size-lg;
            }
        }
    }
}

#search_filters_brands,
#search_filters_suppliers {
    .facet {
        padding-top: 0;
        .facet-label {
            margin-bottom: 0.3125rem;
        }
    }
}

.pagination {
    width: 100%;
    @include media-breakpoint-down(lg) {
        margin: 40px 7px 0 7px;
    }
    @include media-breakpoint-down(sm) {
        margin: 25px 7px 0 7px;
    }
    >div:first-child {
        color: #000;
        font: 400 16px $font-family;
        padding-top: 15px;
    }
    .page-list {
        float: right;
        background: white;
        margin-bottom: 0;
        padding: 0 10px;
        border-radius: 3px;
        li {
            display: inline-block;
            margin: 0 5px;
            a {
                display: inline-block;
                border: 1px solid $gray-lighter;
                padding: 5px 13px;
                i {
                    font-size: 16px;
                }
            }
        }
    }
    a {
        color: #000;
        text-decoration: none;
        font: 700 16px $font-family;
    }
    .disabled {
        color: $gray;
    }
    .current {
        background-color: $brand-primary;
        border: 1px solid $brand-primary;
        a {
            border: none !important;
            color: #fff;
            text-decoration: none;
            font: 700 16px $font-family;
        }
    }
}

.active_filters {
    background: #dededd;
    padding: $small-space $large-space 0;
    margin-bottom: $medium-space;
    .active-filter-title {
        display: inline;
        margin-right: $small-space;
        font-weight: 600;
    }
    ul {
        display: inline;
    }
    .filter-block {
        @include box-shadow;
        color: $gray-darker;
        margin-right: $small-space;
        margin-bottom: $small-space;
        background: white;
        padding: 0.625rem;
        display: inline-block;
        font-size: $font-size-xs;
        .close {
            color: $gray-darker;
            font-size: $font-size-lg;
            opacity: 1;
            margin-top: 0.1875rem;
            margin-left: 0.3125rem;
        }
    }
}

.category-left {
    padding-right: 55px;
    .navbar-toggler {
        width: 1em;
    }
}

.block-category {
    position: relative;
    margin-top: 35px;
    margin-bottom: 0;
    .category-info {
        font: 700 20px $font-family;
        color: $brand-primary;
        margin-bottom: 18px;
        padding-bottom: 13px;
        border-bottom: 1px solid $gray-lighter;
    }
    .category-name {
        font: 500 16px $font-family;
        color: $brand-primary;
        line-height: 1.6;
    }
    #category-description,
    #category-description-short {
        font: 400 15px $font-family;
        color: $brand-secondary;
        // color: #222222;
        line-height: 1.7;
        strong {
            font-weight: 600;
            color: #222222;
        }
        p {
            font-weight: 300;
            color: #222222;
        }
        p {
            span {
                font: 400 14px $font-family !important;
                color: #000 !important;
                line-height: 2 !important;
            }
        }
        ul {
            list-style-position: inside;
            li {
                color: #222222;
            }
        }
        h2 {
            color: #222222;
        }
    }
    #category-description-short,
    #category-description {
        .read-more,
        .read-less {
            font: 500 15px $font-family !important;
            color: $brand-primary !important;
            margin-left: 7px;
            cursor: pointer;
            i {
                vertical-align: middle;
            }
        }
    }
    .category-cover {
        position: absolute;
        right: 0.75rem;
        bottom: 0;
        img {
            width: 141px;
            height: 180px;
        }
    }
}

.block-categories {
    background: white;
    margin-bottom: 30px;
    padding-top: 20px;
    .category-top-menu {
        li[data-depth="0"] {
            .category-sub-menu {
                font-size: 14px;
                font: 400 16px $font-family;
                color: #000;
                margin-bottom: 10px;
                li {
                    list-style: disc;
                    margin-left: 25px;
                }
            }
        }
        >li:first-of-type {
            a {
                display: inline-block;
                text-transform: initial;
                font: 700 18px $font-family;
                color: #000;
                margin-bottom: 19px;
            }
        }
    }
    .category-sub-menu {
        .category-sub-link {
            font: 400 16px $font-family;
        }
        li {
            position: relative;
            &[data-depth="1"] {
                margin-bottom: 2px;
            }
            img {
                display: inline-block;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }
        li[data-depth="0"]>a {
            display: inline-block;
            word-wrap: break-word;
            font: 400 16px $font-family;
            padding: 7px 3px 7px 0;
            width: 100%;
            text-transform: initial;
            >span {
                color: $gray;
                margin-left: 5px;
            }
            .lc-img2 {
                display: none;
            }
            &:hover {
                color: $brand-primary;
                .lc-img {
                    display: none;
                }
                .lc-img2 {
                    display: inline-block;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
        }
        li:not([data-depth="0"]):not([data-depth="1"]) {
            padding-left: 0.3125rem;
            &::before {
                content: "-";
                margin-right: 0.3125rem;
            }
        }
    }
    a {
        color: #000;
    }
    .collapse-icons {
        position: absolute;
        margin-top: 7px;
        right: 0;
        top: 6px;
        padding: 0;
        cursor: pointer;
        color: #000;
        &::before {
            content: "";
            background-image: url("../img/arrow-down-category.png");
            background-repeat: no-repeat;
            width: 10px;
            height: 7px;
            display: block;
        }
        @include media-breakpoint-down(md) {
            margin-top: 4px;
        }
        &[aria-expanded="true"] {
            .add {
                display: none;
            }
            .remove {
                display: block;
            }
        }
        .add,
        .remove {
            &:hover {
                color: $brand-primary;
            }
        }
        .remove {
            display: none;
        }
    }
    .arrows {
        .arrow-right,
        .arrow-down {
            font-size: $font-size-sm;
            cursor: pointer;
            margin-left: 2px;
            &:hover {
                color: $brand-primary;
            }
        }
        .arrow-down {
            display: none;
        }
        &[aria-expanded="true"] {
            .arrow-right {
                display: none;
            }
            .arrow-down {
                display: inline-block;
            }
        }
    }
}

#module-productsliders-view {
    .sort-by-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font: 400 14px $font-family;
        color: #000;
        vertical-align: top;
        padding-top: 7px;
        margin-bottom: 15px;
        label {
            font: 400 14px $font-family;
            color: #000;
        }
        .sort-by {
            margin-right: 2px;
            margin-left: 25px;
            white-space: normal;
            word-break: break-word;
            text-align: right;
            color: #000;
            font: 400 14px $font-family;
            padding-right: 10px;
        }
    }
    @include media-breakpoint-down(sm) {
        .sort-by-row {
            display: inline-block;
            padding-top: 0;
            vertical-align: middle;
            width: 49%;
        }
    }
    .product-miniature {
        position: relative;
        margin-bottom: 20px;
        .thumbnail-container {
            &:hover {
                box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.04);
                transition: box-shadow 0.5s ease-in-out;
                transition: background-color 0.5s ease-in-out;
            }
        }
    }
    .availability-listing {
        text-align: center !important;
        display: block !important;
        .product-available {
            background: unset !important;
            color: $brand-primary !important;
        }
        .product-last-items {
            background: unset !important;
            color: $orange-lighter !important;
        }
    }
    .add-to-cart-listing {
        padding-bottom: 20px;
    }
    .add-to-cart-listing .choose_variant {
        left: 0;
        right: 0;
        bottom: -12px;
        margin-left: auto;
        margin-right: auto;
        min-width: 120px;
        @include media-breakpoint-down(md) {
            padding: 5px 10px;
        }
    }
}

.facets-title {
    color: $gray-darker;
}

.products-selection {
    .filter-button {
        .btn-secondary {
            padding: 0.75rem 0.5rem 0.6875rem;
        }
    }
}

#category #content-wrapper {
    background-color: #fff;
}

#category {
    .category-row {
        padding-top: 50px;
    }
    #products {
        .product-miniature {
            .product-thumbnail {
                padding: 38px 38px 5px 38px;
                @media (max-width: 577px) {
                    padding: 30px 10px 5px 10px;
                }
                @media (max-width: 450px) {
                    padding: 30px 10px 5px 10px;
                }
            }
        }
    }
}

#prices-drop {
    #products {
        .product-miniature {
            .product-thumbnail {
                padding: 38px 38px 5px 38px;
                @media (max-width: 577px) {
                    padding: 30px 10px 5px 10px;
                }
                @media (max-width: 450px) {
                    padding: 30px 10px 5px 10px;
                }
            }
        }
    }
}

#category,
#search,
#module-favoriteproducts17-account,
#prices-drop,
#manufacturer {
    .filter-category {
        display: inline-flex;
        margin-bottom: 10px;
        p {
            font: 400 16px $font-family;
        }
        i {
            color: $gray;
            margin-right: 10px;
        }
    }
    #left-column {
        h1 {
            font-size: 48px;
            color: #000;
            text-transform: uppercase;
            margin-bottom: 35px;
        }
    }
    @media (max-width: 1070px) {
        #left-column {
            h1 {
                font-size: 40px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        #left-column {
            h1 {
                font-size: 30px;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        #left-column {
            h1 {
                display: none;
            }
            .filter-category {
                display: none;
            }
        }
    }
}


/*** Responsive part ***/

@include media-breakpoint-down(sm) {
    #category {
        #left-column {
            width: 100%;
            #search_filters_wrapper {
                margin-left: -30px;
                margin-right: -30px;
            }
            #search_filter_controls {
                text-align: center;
                button {
                    margin: 0 0.5rem;
                }
                margin-bottom: 1rem;
            }
            #search_filters {
                margin-bottom: 0;
                box-shadow: none;
                padding: 0;
                border-top: 1px solid $gray-lighter;
                .facet {
                    .title {
                        cursor: pointer;
                        .collapse-icons .remove {
                            display: none;
                        }
                    }
                    .title[aria-expanded="true"] {
                        .collapse-icons {
                            .add {
                                display: none;
                            }
                            .remove {
                                display: block;
                            }
                        }
                    }
                    padding-top: 0;
                    border-bottom: 1px solid $gray-lighter;
                    .facet-title {
                        color: $gray-darker;
                        text-transform: uppercase;
                    }
                    .h6 {
                        margin-bottom: 0;
                        padding: 0.625rem;
                        display: inline-block;
                    }
                    .navbar-toggler {
                        display: inline-block;
                        padding: 0.625rem 0.625rem 0 0;
                    }
                    .collapse {
                        display: none;
                        &.in {
                            display: block;
                        }
                    }
                    .facet-label {
                        a {
                            margin-top: 0;
                        }
                    }
                    ul {
                        margin-bottom: 0;
                        li {
                            border-top: 1px solid $gray-lighter;
                            padding: 0.625rem;
                        }
                    }
                }
            }
        }
        #content-wrapper {
            width: 100%;
        }
        #search_filter_toggler {
            width: 100%;
        }
    }
    .products-sort-order {
        .select-title {
            margin-left: 0;
        }
    }
    .products-selection {
        h1 {
            padding-top: 0;
            text-align: center;
            margin-bottom: 1rem;
        }
        .showing {
            padding-top: 1rem;
        }
    }
    #prices-drop #content-wrapper,
    #new-products #content-wrapper,
    #best-sales #content-wrapper {
        width: 100%;
    }
}

@include media-breakpoint-down(xs) {
    .products-selection {
        .filter-button {
            padding-left: 0;
        }
    }
    #category {
        #left-column {
            #search_filters_wrapper {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}

#category {
    .category_slider {
        .slider:not(:first-child) {
            .slider-title {
                .h3.slider-title {
                    padding-top: 30px;
                }
            }
        }
        .slider:last-child {
            margin-bottom: 20px;
        }
        .slider-products-slick {
            .slick-list {
                margin: 0 -10px 0 -10px;
            }
            .slick-arrow {
                z-index: 9;
                background: #fff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 50%;
                height: 50px;
                width: 50px;
                right: -18px;
                @media (max-width: 1374px) {
                    right: 13px;
                }
                &:before {
                    content: "";
                    display: block;
                    background-image: url("../img/arrow-slider-right.png");
                    background-repeat: no-repeat;
                    width: 50px;
                    height: 50px;
                    position: absolute;
                    left: 21px;
                    top: 16px;
                }
                &.slick-prev {
                    z-index: 9;
                    background: #fff;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                    border-radius: 50%;
                    height: 50px;
                    width: 50px;
                    left: -14px;
                    @media (max-width: 1374px) {
                        left: 14px;
                    }
                    &:before {
                        display: block;
                        background-image: url("../img/arrow-slider-left.png");
                        background-repeat: no-repeat;
                        width: 50px;
                        height: 50px;
                        position: absolute;
                        left: 19px;
                        top: 15px;
                    }
                }
            }
            .thumbnail-container {
                margin-bottom: 0;
                padding: 0 10px;
                @include media-breakpoint-down(md) {
                    padding: 0 7px;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0 4px;
                }
                .favourite-badge {
                    .favourite-button {
                        i {
                            &::before {
                                font-size: 24px;
                            }
                        }
                    }
                }
                .product-description {
                    .availability-listing {
                        font: 400 15px $font-family;
                    }
                    .product-name-variant {
                        .h3.product-title {
                            a {
                                font: 500 18px $font-family;
                                @media (max-width: 577px) {
                                    font: 500 15px $font-family;
                                }
                            }
                        }
                    }
                    .product-price-and-shipping {
                        .price {
                            font: 500 20px $font-family;
                            .price_tax_exc {
                                font-size: 14px;
                                @include media-breakpoint-down(xs) {
                                    font-size: 12px;
                                }
                            }
                            @include media-breakpoint-down(xs) {
                                font: 500 16px $font-family;
                            }
                        }
                        .regular-price {
                            font: 500 20px $font-family;
                            @include media-breakpoint-down(lg) {
                                font: 500 18px $font-family;
                            }
                            @include media-breakpoint-down(xs) {
                                font: 500 15px $font-family;
                            }
                        }
                        .price,
                        .regular-price {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .add-to-cart-listing {
                        .choose_variant {
                            padding: 0.5rem 0.5rem;
                            min-width: 120px;
                            @include media-breakpoint-down(md) {
                                padding: 5px 10px;
                            }
                            span {
                                font: 400 14px $font-family;
                            }
                        }
                    }
                }
                .product-flags {
                    .product-flag {
                        @include media-breakpoint-down(sm) {
                            left: 6px;
                        }
                    }
                    .product-flag,
                    .flag-percentage {
                        width: 57px;
                        height: 25px;
                        font: 700 15px $font-family;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .h3.slider-title {
            margin-bottom: 0;
            padding: 5px 0 30px 0;
        }
    }
}