.ui-autocomplete {
    z-index: 9999999;
    .ui-front {
        background: #fff;
        border: 1px solid #ddd;
        li {
            border-bottom: 1px solid #ddd;
            cursor: pointer;
            display: flex;
            &:last-child {
                border-bottom: none;
            }
            .product-image {
                display: block;
                float: left;
                padding: 10px;
                margin: auto 0;
                &:hover {
                    border: none;
                }
                img {
                    max-width: 60px;
                }
            }
            .product-info {
                display: block;
                float: left;
                padding: 20px;
                &:hover {
                    border: none;
                }
                a {
                    display: block;
                    font: 14px/21px $font-family;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    color: #000;
                    padding: 0 !important;
                }
                span {
                    font-family: $font-family;
                    font-size: 14px;
                    .no-reduction {
                        color: #ec4747;
                    }
                }
            }
        }
    }
}

.search-ref {
    font: 500 13px $font-family;
}

#search_block_top #search_query_top.no-round-bottom {
    -moz-border-radius: 32px 32px 0 0;
    -webkit-border-radius: 32px;
    border-radius: 32px 32px 0 0;
}

#ui-id-1.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
    border: 0;
}

.ui-state-focus {
    border: none !important;
    background: none !important;
    padding: 10px !important;
}

.old-price {
    text-decoration: line-through;
    font-size: 12px !important;
    margin-right: 5px;
}

.searchresult {
    position: absolute;
    right: 0;
    max-height: 360px;
    overflow: hidden;
    min-width: unset !important;
    .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        bottom: 0;
        left: 0;
        &:focus {
            outline: thin dotted;
        }
         ::-webkit-scrollbar {
            display: none;
        }
    }
    .nano-pane {
        background: #d7ebf5;
        position: absolute;
        width: 7px;
        height: 360px;
        right: 0;
        top: 0;
        bottom: 0;
        visibility: hidden \9;
        opacity: .4;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        z-index: 9999999;
        .nano-slider {
            background: #21a5e3;
            opacity: .8;
            position: relative;
            margin: 0 1px;
            -moz-border-radius: 3px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
        }
    }
}

.has-scrollbar .nano-content::-webkit-scrollbar {
    display: block;
}

.searchresult .nano-pane,
.searchresult2 .nano-pane {
    background: #ddd;
    position: absolute;
    width: 7px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden \9;
    opacity: .4;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    z-index: 9999999;
}

.searchresult,
.searchresult2 {
    position: absolute;
    max-height: unset;
    overflow: hidden;
    right: 0px;
}

.searchresult:hover .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
    visibility: visible \9;
    opacity: 0.99;
}

.sec_search_widget {
    width: 100% !important;
}

.sec_search_widget form {
    position: relative;
    color: $gray;
    input[type="search"] {
        color: $gray-darker;
        font: 300 14px $poppins;
        width: 100% !important;
        height: 45px;
        padding-left: 15px;
        margin-top: 3px;
        outline: none;
        background-color: #fff;
        border-radius: 25px;
        border: 1px solid;
        border-color: $gray-light;
        &::placeholder {
            color: $gray-darker;
        }
    }
    .search-button {
        position: absolute;
        top: 5px;
        right: 0;
        height: 40px;
        width: 40px;
        border: none;
        cursor: pointer;
        padding: 0;
        outline: none;
        color: $brand-secondary;
        background-color: unset;
        font-size: 16px;
        .search-icon {
            &:before {
                width: 25px;
                height: 20px;
                background: url("../img/search1.svg");
                background-repeat: no-repeat;
                background-size: auto 22px;
            }
        }
    }
    input::placeholder {
        color: $gray;
        opacity: 1;
    }
}

#_mobile_search_trigger {
    cursor: pointer;
    font-size: 17px;
    .fa-search {
        font-size: 19px;
        padding: 0 10px 10px 10px;
    }
}

#_mobile_language_selector {
    margin-left: 20px;
    @include media-breakpoint-down(xs) {
        margin-left: 5px;
        margin-top: 1px;
    }
    .language-selector {
        img {
            margin-bottom: 4px;
            max-height: 18px;
        }
        .expand-more{
            @include media-breakpoint-down(xs){
                font-size: 13px;
            }
        }
    }
}

.spinner-icon {
    display: none;
}

.close-icon {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    position: absolute;
    right: 40px;
    top: 0px;
    bottom: 0;
    padding-top: 10px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
        top: 6px;
    }
}

.hide-search {
    display: none;
}

.show-spinner {
    position: absolute;
    top: 16px;
    right: 50px;
    bottom: 0;
    z-index: 9999;
    display: none !important;
    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}

.hidden-sm-up-close-button {
    display: none !important;
    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}

.modal-search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    bottom: 0;
    background-color: $gray-lightest;
    width: 100% !important;
    .sec_search_widget {
        display: block;
        float: none;
        margin-top: 80px;
        z-index: 99999;
        @include media-breakpoint-down(xs) {
            margin-top: 15px;
        }
    }
}

.ui-autocomplete.ui-front li .product-info span.no-reduction {
    color: $brand-primary !important;
}

.js-cat-name {
    font: 500 14px $font-family;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    *::-ms-clear,
    *:-ms-reveal {
        display: none;
    }
    .searchresult {
        -ms-overflow-style: none !important;
        overflow: auto !important;
    }
}

@include media-breakpoint-down(sm) {
    .sec_search_widget {
        display: none;
    }
    .sec_search_widget form .search-button {
        width: 45px;
    }
}