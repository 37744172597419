#product {
    #content {
        position: relative;
        padding-right: 30px;
        @include media-breakpoint-down(sm) {
            padding-right: 0;
        }
    }
    .h1 {
        text-transform: initial;
        font: 700 25px $font-family;
        color: #000;
        margin-bottom: 0;
    }
    .availability-listing {
        text-align: left;
        display: inline-block;
        .product-available {
            padding: 2px 7px;
            color: #fff;
            background-color: $brand-primary;
        }
        .product-last-items {
            padding: 2px 7px;
            color: #fff;
            background-color: $orange-lighter;
        }
    }
    .product-reference {
        .label,
        span {
            font: 400 14px $font-family;
            color: $brand-secondary;
        }
    }
    .description-short {
        .description {
            font: 400 15px $font-family;
            color: #000;
            border-bottom: 1px solid $gray-lighter;
            padding-bottom: 35px;
            margin-bottom: 35px;
            line-height: 1.5;
            .read-more,
            .read-less {
                cursor: pointer;
                color: $brand-primary;
                font: 500 15px $font-family;
            }
            ul {
                margin-bottom: 0;
                margin-left: 19px;
            }
            p {
                color: #000;
            }
        }
    }
    .product-prices {
        .price_tax_exc {
            font-size: 15px;
            font-weight: 500;
            margin-left: 5px;
            vertical-align: middle;
            @media (max-width: 443px) {
                font-size: 13px;
            }
        }
        display: inline-flex;
        .has-discount.product-price {
            .current-price {
                .price_tax_exc {
                    font-size: 15px;
                    font-weight: 500;
                    margin-left: 5px;
                    vertical-align: middle;
                    @media (max-width: 443px) {
                        font-size: 13px;
                    }
                }
                span {
                    font: 500 28px $font-family;
                    color: $reduction;
                    @media (max-width: 443px) {
                        font: 500 20px $font-family;
                    }
                }
                span.color-price {
                    color: #000;
                }
                .discount.discount-percentage,
                .discount.discount-amount {
                    font: 500 15px $font-family;
                    color: #fff;
                    background-color: $reduction;
                    padding: 1px 15px;
                    vertical-align: super;
                    margin-left: 15px;
                    @media (max-width: 443px) {
                        vertical-align: middle;
                        font: 500 13px $font-family;
                    }
                }
            }
        }
        .product-discount {
            margin-top: 8px;
            .regular-price {
                font: 500 18px $font-family;
                color: $brand-secondary;
                margin-left: 15px;
                @include media-breakpoint-down(sm) {
                    vertical-align: -webkit-baseline-middle;
                }
                @media (max-width: 443px) {
                    font: 500 16px $font-family;
                }
            }
        }
    }
}

.product-add-to-cart {
    .qty {
        margin-right: 15px;
        border-radius: 50px;
        border: 1px solid #000;
    }
    .product-quantity {
        display: flex !important;
        flex-direction: row;
    }
    .input-group {
        border: 1px solid transparent;
    }
    .input-group .input-group-btn {
        width: unset;
    }
    .input-group.bootstrap-touchspin {
        border-radius: 50px;
    }
    .input-group .input-group-btn>.btn {
        color: #000;
        font-size: 20px;
        height: 100%;
    }
    .product-quantity #quantity_wanted {
        border: unset !important;
        height: 100%;
        text-align: center;
        width: 3.4rem !important;
        color: #000;
        font: 500 15px $font-family;
    }
    .bootstrap-touchspin .btn-touchspin:hover {
        background-color: unset;
    }
    .bootstrap-touchspin {
        .btn-touchspin {
            border-radius: 50px;
        }
    }
}

.prod-right {
    padding-left: 50px;
    @include media-breakpoint-down(md) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.product-name {
    font: 700 48px $font-family;
    padding-bottom: 15px;
    margin: 0;
    text-transform: uppercase;
    color: $brand-primary;
    @include media-breakpoint-down(md) {
        padding-bottom: 5px;
    }
    @include media-breakpoint-down(sm) {
        margin-top: 27px;
        font: 700 40px $font-family;
    }
}

.price-ref {
    border-bottom: 1px solid $border-gray;
    display: flex;
    align-items: flex-end;
}

.prod-ref-w {
    @include media-breakpoint-down(md) {
        // padding: 10px 0;
    }
    .prod-ref {
        margin-bottom: 20px;
        display: inline-flex;
        font: 400 18px $font-family;
        color: $gray-text;
        span {
            font-weight: 700;
            padding-left: 5px;
        }
    }
    .comments_note {
        display: inline-flex;
        padding-left: 15px;
    }
}

.select-pack {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-bottom: 10px;
    .custom-checkbox,
    .name {
        vertical-align: middle;
    }
    .name {
        text-transform: lowercase;
    }
}

.product-price {
    color: $brand-primary;
    display: inline-block;

}

#product-description-short {
    color: $gray-darker;
}

.product-information {
    font-size: $font-size-lg;
    color: $gray-darker;
    .manufacturer-logo {
        height: 35px;
    }
    .product-description img {
        max-width: 100%;
        height: auto;
    }
    .reasurance-container {
        border-top: 1px solid $gray-lighter;
        border-bottom: 1px solid $gray-lighter;
        padding: 35px 0;
        .reassurance-item-product {
            text-align: center;
            img {
                margin-bottom: 5px;
            }
        }
    }
}

.input-color {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
}

.input-container {
    position: relative;
}

.input-radio {
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.input-color,
.input-radio {
    &:checked+span,
    &:hover+span {
        border: 2px solid $gray-darker;
    }
}

.radio-label {
    @include box-shadow;
    background: white;
    display: inline-block;
    padding: 0.125rem $small-space;
    font-weight: 600;
    border: 2px solid white;
}

.product-add-to-cart {

    margin-right: 15px;
    .product-quantity {
        display: inline-block;
        width: 100%;
        .input-group {
            display: flex;
            width: 100%;
        }
        #quantity_wanted {
            width: 100%;
            border: 1px solid $gray-darker;
        }
    }
}

.product-actions {
    #add-to-cart-or-refresh {
        .product-add-to-cart-form {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .control-label {
        width: 100%;
        margin-bottom: 0.375rem;
        display: block;
        width: 100%;
        text-transform: uppercase;
    }
    .add {
        text-align: center;
    }
    .add-to-cart-center {
        text-align: center;
    }
    .empty-atribute {
        text-align: right;
        .add-to-cart {
            margin-top: 15px;
        }
    }
    .group-atribute-1 {
        text-align: left;
    }
    .add-to-cart,
    .choose-option {
        display: inline-block;
        background-color: #000;
        padding: 14px 24px;
        position: relative;
        border: none;
        box-shadow: none;
        text-transform: none;
        font: 500 14px $font-family;
        border-radius: 50px;
        .icon-cart {
            display: inline-block;
            background-image: url("../img/cart-button.png");
            background-repeat: no-repeat;
            height: 18px;
            width: 24px;
            vertical-align: middle;
            margin-right: 10px;
        }
        span {
            display: inline-block;

            background-repeat: no-repeat;
            height: 26px;
            width: 21px;

            overflow: hidden;
            vertical-align: middle;
            position: absolute;
            left: 37px;
            top: 10px;
        }
        &:hover,
        &:active {
            background-color: #000;
        }
    }
    .choose-option {
        a {
            text-decoration: unset;
            color: #fff;
            i {
                font-size: 16px;
                margin-right: 17px;
                background-image: url("../img/choose-option.png");
                background-repeat: no-repeat;
                display: inline-block;
                height: 8px;
                width: 14px;
            }
        }
    }
    .add-remove-favorites {
        ul {
            border: 1px solid #000;
            border-radius: 50px;
            padding: 12px 23px;
            #favoriteproducts_block_extra_add {
                color: #000;
                font: 500 14px $font-family;
                i {
                    background-image: url("../img/heart-product.png");
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 20px;
                    width: 23px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
            #favoriteproducts_block_extra_remove {
                color: #000;
                font: 500 14px $font-family;
                i {
                    background-image: url("../img/heart-product-active.png");
                    background-repeat: no-repeat;
                    display: inline-block;
                    height: 20px;
                    width: 23px;
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }
}

.product-quantity {
    display: flex;
    .qty,
    .add {
        float: left;
        display: inline-flex;
        margin-bottom: 0.5rem;
    }
    #quantity_wanted {
        color: $gray-darker;
        background-color: white;
        height: 2.65rem;
        padding: 0.175rem 0.5rem;
        width: 100%;
    }
    .input-group-btn-vertical {
        display: flex;
        flex-direction: row-reverse;
        .btn {
            height: auto;
            padding: 20px 25px;
            border: 1px solid $gray-darker;
            &:hover {
                background-color: $gray-darker;
                i {
                    color: #fff;
                }
            }
            i {
                font-size: 33px;
                top: 3px;
                left: 7px;
                color: $gray-darker;
            }
        }
        .bootstrap-touchspin-down {
            margin-top: 0;
        }
    }
    .btn-touchspin {
        height: 1.438rem;
    }
}

.product-discounts {
    >.product-discounts-title {
        text-align: center;
        font: 700 16px $font-family;
    }
    >.table-product-discounts {
        float: right;
        thead tr th {
            width: 33%;
            padding: 5px 1px;
            background: white;
            border: 1px $gray-light solid;
            text-align: center;
        }
        tbody tr {
            &:nth-of-type(even) {
                background: white;
            }
            td {
                padding: 5px 1px;
                text-align: center;
                border: 1px $gray-light solid;
            }
        }
    }
    @include media-breakpoint-down(md) {
        width: unset;
    }
    @include media-breakpoint-down(sm) {
        float: left;
        .product-discounts-title {
            text-align: left;
        }
    }
}

.product-prices {
    display: inline-block;
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    p {
        margin-bottom: 0;
    }
    .regular-price {
        font: 700 20px $font-family;
    }
    .current-price span {
        font: 700 32px $font-family;
        color: #000;
        @media (max-width: 443px) {
            font: 500 20px $font-family;
        }
    }
    h5 {
        margin-bottom: 0;
    }
    .tax-shipping-delivery-label {
        font-size: 0.8125rem;
        color: $gray;
    }
}

.product-discount {
    color: $gray;
    font-size: 20px;
    .regular-price {
        text-decoration: line-through;
        margin-left: 10px;
    }
}

.product-quantities {
    text-align: right;
    vertical-align: 5px;
    display: inline-block;
    width: 47%;
    @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 10px;
    }
    @include media-breakpoint-down(sm) {
        text-align: left;
    }
    span {
        border-radius: 3px;
        font: 700 14px $font-family;
        color: #fff;
        text-transform: uppercase;
        padding: 3px 10px;
        margin-bottom: 5px;
        &.product-stock {
            background-color: $brand-success;
            &.limited-stock {
                background-color: $brand-warning;
            }
            &.out-of-stock {
                background-color: $brand-danger;
            }
        }
    }
}

.has-discount {
    &.product-price,
    p {
        color: $brand-secondary;
    }
    .discount {
        background: #fff;
        color: $gray-darker;
        font-weight: 600;
        padding: 0.3125rem $small-space;
        font-size: $font-size-base;
        margin-left: $small-space;
        text-transform: uppercase;
        display: inline-block;
    }
}

.product-unit-price {
    font-size: $font-size-xs;
    margin-bottom: 0;
}

.wrapper-top-description {
    .button-description-attachments {
        .button-description,
        .button-attachments {
            padding: 0;
            margin-bottom: 5px;
            padding-right: 5px;
        }
    }
    #description {
        padding: 25px 0 15px 0;
        p {
            line-height: 1.7;
        }
        span {
            color: $gray-darker;
            font: 400 18px $roboto;
        }
    }
    .nav-item {
        list-style-type: none;
        .nav-link {
            width: 100%;
            border: 1px solid $gray;
            background-color: $brand-primary;
            color: #fff;
            font-weight: 400;
            font-size: 18px;
            text-transform: uppercase;
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 15px;
            padding-bottom: 15px;
            @media (max-width: 1248px) {
                font-size: 15px;
            }
            @media (max-width: 1247px) {
                padding-left: 50px;
                padding-right: 50px;
            }
            @include media-breakpoint-down(lg) {
                padding-left: 15px;
                padding-right: 30px;
            }
            i {
                color: #fff;
            }
        }
    }
    .attachment {
        position: relative;
        border: 1px solid $gray;
        font-size: 18px;
        font-weight: 400;
        color: #000;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 15px;
        padding-bottom: 15px;
        text-transform: uppercase;
        @media (max-width: 1247px) {
            padding-left: 50px;
            padding-right: 50px;
        }
        @include media-breakpoint-down(lg) {
            padding-left: 15px;
            padding-right: 30px;
        }
        i {
            color: $gray;
            font-size: 14px;
            position: absolute;
            top: 17px;
            right: 8px;
        }
        h4 {
            margin-bottom: 0;
            a {
                font-size: 18px;
                font-weight: 400;
                color: #000;
                @media (max-width: 1248px) {
                    font-size: 15px;
                }
            }
        }
    }
}

.block-reassurance {
    border-bottom: 1px solid $border-gray;
    margin-top: 50px;
    @include media-breakpoint-down(md) {
        margin-bottom: 50px;
    }
    .blockreassurance_product {
        display: flex;
        margin-bottom: 25px;
        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            .item-product {
                width: unset;
                height: unset;
                @media (max-width: 1017px) {
                    height: 77px;
                }
                @include media-breakpoint-down(md) {
                    height: 40px;
                }
                @include media-breakpoint-down(sm) {
                    height: unset;
                }
                @include media-breakpoint-down(xs) {
                    height: 40px;
                    width: 40px;
                }
                img {
                    width: unset;
                    height: unset;
                    @media (max-width: 1053px) {
                        text-align: center;
                    }
                    @include media-breakpoint-down(md) {
                        height: 40px;
                    }
                    @include media-breakpoint-down(sm) {
                        height: unset;
                    }
                    @include media-breakpoint-down(xs) {
                        height: 40px;
                        width: 40px;
                    }
                }
            }
            .block-title {
                color: $gray !important;
                font-size: 15px;
            }
        }
        p.block-title {
            line-height: unset;
            padding: 15px 0;
            font: 400 16px $roboto;
            color: $gray-darker !important;
            @media (max-width: 1053px) {
                text-align: center;
            }
        }
    }
}

.tabs-wrapper {

    .tabs {
        background-color: #fff;
        .nav-tabs {
            border-bottom: 1px solid $border-gray;
            margin: 0;
            .nav-item {
                position: relative;
                margin: 0 0 -1px 0;
                float: left;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            .nav-link {
                text-transform: uppercase;
                padding: 13px 27px;
                &.active {
                    border-top: 2px solid $brand-primary;
                    border-bottom: 1px solid #fff;
                    border-right: 1px solid $border-gray;
                    box-shadow: 6px 6px 4px -1px rgba(220, 220, 220, 0.23);
                }
            }
        }
    }
}

.tabs {
    background: white;
    margin-bottom: 55px;
    .tab-pane {
        padding-top: $medium-space;
    }
    .nav-tabs {
        border: none;
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 13px;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        .nav-link {
            color: #000;
            font: 500 18px $font-family;
            border: 0 solid transparent;
            padding: 15px 30px;
            &.active {
                border: none;
                font: 700 18px $font-family;
                color: #000;
                padding: 15px 30px;
                @include media-breakpoint-down(xs) {
                    padding: 14px 30px;
                }
            }
        }
        .nav-item {
            float: left;
            margin-bottom: -0.825rem;
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }
    .tab-content {
        .product-description-long {
            font: 400 15px $font-family;
            line-height: 1.5;
            .read-more,
            .read-less {
                cursor: pointer;
                color: $brand-primary;
                font: 500 15px $font-family;
            }
            span,p{
                font-size:15px;
            }
        }
        #product-description-info {
            .short {
                p,span {
                    color: #595959;
                    font-size:15px !important;
                    font-family:$font-family !important;
                }
            }
        }
        #product-description {
            .long {
                p {
                    color: #595959;
                }
            }
        }
    }
}

.prod-banner-wrapper {
    padding-top: 40px;
}

.product-cover {
    position: relative;
    background-color: $gray-lightest;
    img {
        background-color: $gray-lightest;
        width: 100%;
    }
    .layer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        background: rgba(255, 255, 255, 0.7);
        text-align: center;
        cursor: pointer;
        @include transition(opacity 0.7s ease-in-out);
        &:hover {
            opacity: 1;
        }
        .zoom-in {
            font-size: 6.25rem;
            color: $gray;
        }
    }
}

#product-modal {
    .modal-content {
        background: transparent;
        border: none;
        padding: 0;
        .modal-body {
            display: flex;
            margin-left: -30%;
            .product-cover-modal {
                background: white;
            }
            .image-caption {
                background: white;
                width: 800px;
                padding: 0.625rem 1.25rem;
                border-top: $gray-light 1px solid;
                p {
                    margin-bottom: 0;
                }
            }
            .thumbnails {
                position: relative;
            }
            .mask {
                position: relative;
                overflow: hidden;
                max-height: 49.38rem;
                margin-top: 2.188rem;
                z-index: 1;
                &.nomargin {
                    margin-top: 0;
                    margin-left: 10px;
                }
            }
            .product-images {
                margin-left: $extra-large-space;
                img {
                    width: 9.25rem;
                    cursor: pointer;
                    background: $gray-lightest;
                }
            }
            .arrows {
                height: 100%;
                width: 100%;
                text-align: center;
                position: absolute;
                top: 0;
                color: white;
                right: $large-space;
                z-index: 0;
                .arrow-up {
                    position: absolute;
                    top: -2rem;
                    opacity: 0.2;
                }
                .arrow-down {
                    position: absolute;
                    bottom: -2rem;
                }
                i {
                    font-size: 6.25rem;
                    display: inline;
                }
                cursor: pointer;
            }
        }
    }
}

.product-slider {
    article {
        padding: 0;
        a {
            padding: 0 !important;
            img {
                background-color: $gray-lightest;
                padding: 40px;
            }
        }
        .product-description {
            .h3 {
                padding: 0;
                overflow: visible !important;
                a {
                    text-align: left;
                    font-weight: 700;
                    color: #000;
                    margin-right: 5px;
                    justify-content: center;
                }
            }
        }
    }
}

#product .productsliders-top {
    .h3 {
        color: #000 !important;
        font: 700 28px $font-family;
        padding: 60px 0 30px 0;
        margin: 0;
        text-transform: unset !important;
        text-align: center;
    }
}

.category-products {
    position: relative;
    .slick-arrow {
        top: 43%;
        &:before {
            content: "";
            display: block;
            background-image: url("../img/arrow-slider-right.png");
            background-repeat: no-repeat;
            width: 53px;
            height: 106px;
            right: -37px;
            position: absolute;
            right: -53px;
        }
        &.slick-prev {
            &:before {
                display: block;
                background-image: url("../img/arrow-slider-left.png");
                background-repeat: no-repeat;
                width: 53px;
                height: 106px;
                position: absolute;
                left: -48px;
            }
        }
    }
    h2.h3.product-title {
        min-height: 55px;
        max-height: 55px;
    }
}

.product-accessories,
.product-slider-accessories {
    position: relative;
    .slick-arrow {
        top: 26%;
        &:before {
            content: "";
            display: block;
            background-image: url("../img/arrow-slider-right.png");
            background-repeat: no-repeat;
            width: 10px;
            height: 20px;
            position: absolute;
            right: 18px;
            top: 31%;
        }
        &.slick-prev {
            &:before {
                display: block;
                background-image: url("../img/arrow-slider-left.png");
                background-repeat: no-repeat;
                width: 10px;
                height: 20px;
                position: absolute;
                left: 16px;
            }
        }
    }
    .slider-title {
        color: #000;
        font: 700 28px $font-family;
        text-align: center;
        padding: 60px 0 30px 0;
    }
    .availability-listing {
        text-align: center !important;
        display: block !important;
        .product-available {
            background: unset !important;
            color: $brand-primary !important;
        }
        .product-last-items {
            background: unset !important;
            color: $orange-lighter !important;
        }
    }
    .favourite-badge {
        span {
            display: none;
        }
    }
    .add-to-cart-listing {
        padding-bottom: 20px;
    }
    .add-to-cart-listing .choose_variant {
        left: 0;
        right: 0;
        bottom: -12px;
        margin-left: auto;
        margin-right: auto;
        min-width: 120px;
        @include media-breakpoint-down(md) {
            padding: 5px 10px;
        }
    }
    .thumbnail-container {
        margin-bottom: 0;
        padding: 0 6px;
        &:hover {

            box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.04);
            transition: box-shadow 0.5s ease-in-out;
            transition: background-color 0.5s ease-in-out;
        }
    }
    .slick-arrow {
        z-index: 9;
        background: #fff;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        right: -18px;
        top: 50%;
        @media (max-width: 1374px) {
            right: 13px;
        }
        &.slick-prev {
            z-index: 9;
            background: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            left: -14px;
            top: 50%;
            @media (max-width: 1374px) {
                left: 14px;
            }
        }
    }
}

.product-double-slider {
    article {
        width: 100%;
        a {
            padding: 0;
        }
    }
    .single-product {
        display: inline-grid !important;
        height: inherit;
        a {
            padding: 0;
            img {
                padding: 174px 0;
                @media (max-width: 1197px) {
                    padding: 168px 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 128px 0;
                }
                @include media-breakpoint-down(sm) {
                    padding: 139px 0;
                }
            }
        }
    }
}

.product-description-full {
    h1.product-name {
        font: 700 48px $font-family;
        padding: 65px 0;
        margin: 0;
        text-transform: uppercase;
        color: $brand-primary;
        @include media-breakpoint-down(sm) {
            font-size: 40px;
            padding: 25px 0;
        }
    }
    h1 {
        color: #000;
        font-size: 24px;
        text-transform: uppercase;
        padding-bottom: 30px;
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }
    .product-description {
        display: block;
        background-color: #fff;
        p {
            font: 400 18px $roboto;
        }
    }
    p {
        margin-bottom: 40px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 15px;
        }
        &:first-child {
            margin-top: 0.5rem;

            @include media-breakpoint-down(sm) {
                padding-top: 0;
            }
        }
        span {
            font-size: 18px !important;
            color: $gray-darker;
            line-height: 1.7;
        }
    }
    .fade {
        opacity: unset;
        transition: unset;
        margin-top: 0.5rem;
        padding-top: 30px;
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
    }
    .left-description {
        padding-right: 55px;
        @include media-breakpoint-down(sm) {
            padding-right: 7px;
            margin-bottom: 15px;
        }
    }
    .right-description {
        padding-left: 55px;
        @include media-breakpoint-down(sm) {
            padding-left: 7px;
        }
    }
}

.bck-to-categ {
    padding: 5px;
    margin-bottom: 20px;
    margin-left: 15px;
}

#blockcart-modal {
    color: $gray-darker;
    .modal-header {
        background: $brand-primary;
        padding: 10px;
    }
    .modal-body {
        background: #fff;

        .divide-right span,
        .modal-prod-quantity {
            display: inline-block;
            margin-bottom: 0.3125rem;
            font: 400 16px $font-family;
            color: #000;
        }
    }
    .modal-dialog {
        max-width: 1140px;
        width: 100%;
    }
    .product-image {
        width: 100%;
        margin: 0;
    }
    .modal-title {
        color: white;
        font: 400 18px $font-family;
        // text-align: center;
        margin-top: 4px;
        i.material-icons {
            margin-right: 0.875rem;
            vertical-align: -5px;
        }
    }
    .modal-total {
        font: 700 16px $font-family;
        color: $gray-dark;
    }
    .product-name {
        color: $gray-dark;
        font: 600 16px $font-family;
        padding-bottom: 0;
    }
    .modal-prod-price {
        font: 700 18px $font-family;
        color: $gray-dark;
        margin-bottom: 0;
    }
    p.regular-price {
        text-decoration: line-through;
        font-size: 18px;
        color: $gray-dark;
    }
    .modal-header .close {
        margin-top: 4px;
    }
    .cart-content {
        float: right;
        .cart-content-btn {

            button {
                margin-right: 0.3125rem;
            }
            .btn {
                font: 400 14px $font-family;
                white-space: inherit;
                box-shadow: none;
                border-radius: 5px;
                padding: 10px;
                width: 100%;
                text-transform: uppercase;
            }
            .end-order {
                background-color: $brand-primary;
                margin-bottom: 0;
                &:hover {
                    background-color: darken($brand-primary, 10%);
                }
            }
        }
        p {
            font: 400 16px $font-family;
        }
        .cart-products-count {
            font: 700 16px $font-family;
        }
    }
}

.modal-cell {
    vertical-align: middle;
    display: table-cell;
    float: none;
    p {
        margin-bottom: 0;
    }
}

.img-content {
    .favourite-button {
        i {
            margin-right: 25px;
            margin-top: 7px;
        }
        span {
            display: none;
        }
    }
    .cover-image {
        padding: 5px;
        background: $gray-lightest;
        margin-bottom: 15px;
        img {
            width: 350px;
            height: 350px;
            margin-left: auto;
            margin-right: auto;
            background: $gray-lightest;
            @include media-breakpoint-down(sm) {
                width: 100%;
                height: auto;
            }
        }
    }
    .slick-prev,
    .slick-next {
        height: 50px;
        width: 50px;
    }
    .slick-prev {
        left: -20px;
        top: unset;
        bottom: 7%;
        &:before {
            content: "";
            background: url("../img/product-prev.png");
            background-repeat: no-repeat;
            display: block;
            width: 23px;
            height: 39px;
            @include media-breakpoint-down(lg) {
                background: unset;
            }
        }
    }
    .slick-next {
        right: -45px;
        bottom: 7%;
        top: unset;
        &:before {
            content: "";
            background: url("../img/product-next.png");
            background-repeat: no-repeat;
            display: block;
            width: 23px;
            height: 39px;
            @include media-breakpoint-down(lg) {
                background: unset;
            }
        }
    }
    .slick-slider {
        position: unset;
    }
}

.prod-imgs-slider {
    .product-images {
        margin: 0;
        li.thumb-container {
            display: inline;
            border: transparent 1px solid;
            &.selected,
            &:hover {
                border: $gray-lighter 1px solid;
            }
            .thumb {
                cursor: pointer;
                .images-container & {}
            }
        }
    }
}

.product-images li.thumb-container {
    background-color: $gray-lightest;
    margin-right: 4px;
    .thumb {
        padding: 40px;
        margin: -14.6px;
    }
}

.productsliders_block .slider-container .thumbnail-container {
    .add-to-cart-listing {
        padding-bottom: 20px;
        .choose_variant {
            left: 0;
            right: 0;
            bottom: -12px;
            margin-left: auto;
            margin-right: auto;
            min-width: 120px;
            @include media-breakpoint-down(md) {
                padding: 5px 10px;
            }
        }
    }
    &:hover {
        box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.04);
        transition: box-shadow 0.5s ease-in-out;
        transition: background-color 0.5s ease-in-out;
    }
}

.productsliders_block .slider-container .product-miniature {
    padding: 0;
    margin: 0 12px;
    background-color: #f5f5f5;
    @media (max-width: 577px) {
        margin: 0 5px;
    }
    &:hover {
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.01);
        transition: box-shadow 0.5s ease-in-out;
    }
}

#main {
    .images-container {
        position: relative;
        &:hover {
            .slick-prev,
            .slick-next {
                display: inline-block !important;
            }
        }
        .js-qv-mask {
            white-space: nowrap;
            overflow: hidden;
            margin-top: 4px;
            position: unset !important;
            &:hover {
                .slick-prev,
                .slick-next {
                    display: inline-block !important;
                }
            }
            .slick-prev,
            .slick-next {
                display: none !important;
            }
            .slick-prev {
                left: -20px;
                top: unset;
                bottom: 6%;
                z-index: 9;
                background-color: #fff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 50%;
                padding: 10px;
                width: 30px;
                height: 30px;
                &::before {
                    content: "";
                    background-image: url("../img/arrow-left-product-page.png");
                    background-repeat: no-repeat;
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
            .slick-next {
                right: -20px;
                top: unset;
                bottom: 6%;
                z-index: 9;
                background-color: #fff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 50%;
                padding: 10px 12px;
                width: 30px;
                height: 30px;
                &::before {
                    content: "";
                    background-image: url("../img/arrow-right-product-page.png");
                    background-repeat: no-repeat;
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.scroll-box-arrows {
    display: none;
    &.scroll {
        display: block;
    }
    i {
        position: absolute;
        bottom: 1.625rem;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
    }
    .left {
        left: 0;
    }
    .right {
        right: 0;
    }
}

#product-details {
    .label {
        font-size: $font-size-base;
        color: $gray-darker;
        font-weight: bold;
    }
}

.product-features {
    margin-left: 0.3125rem;
    >dl.data-sheet {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        font: 400 18px $font-family;
        dd.value,
        dt.name {
            flex: 1 0 40%;
            font-weight: normal;
            background: $gray-lightest;
            padding: 5px;
            margin-right: $small-space;
            min-height: 2.5rem;
            word-break: normal;
            font-size: 16px;
            display: flex;
            align-items: center;
            &:nth-of-type(even) {
                background: $gray-lightest;
            }
        }
        dt.name {
            text-transform: capitalize;
        }
    }
}

.product-variants {
    display: flex;
    .form-control-select {
        border: 1px solid $gray-darker;
        background: url("../img/arrow-down.png") no-repeat scroll right 0.5rem top 0.3rem / 1.25rem 1.25rem;
        padding: 0 0.5rem;
    }
    >.product-variants-item {
        margin-right: 30px;
        flex-grow: 1;
        select.form-control:not([size]):not([multiple]) {
            height: 2.625rem;
        }
        @media (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 20px;
        }
        &:first-child {
            @media (max-width: 991px) {
                margin-right: 10px;
            }
        }
        select {
            background-color: white;
        }
        .color {
            margin-left: 0;
            margin-top: 0;
        }
    }
    @include media-breakpoint-down(sm) {
        >.product-variants-item {
            text-align: left;
            ul li {
                float: left;
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }
}

.product-flags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    margin-top: 10px;
    margin-left: 10px;
    @include media-breakpoint-down(xs) {
        width: calc(100% - 30px);
    }
}

li.product-flag,
.discount-flag {
    display: block;
    width: 57px;
    height: 25px;
    position: relative;
    text-transform: uppercase;
    color: #000;
    z-index: 2;
    &.online-only {
        top: 25rem;
        right: 0;
        font-size: $font-size-xs;
        &::before {
            content: "\E30A";
            font-family: "Material Icons";
            vertical-align: middle;
            margin: 0.3125rem;
        }
    }
    &.discount {
        display: none;
    }
    &.on-sale {
        background: $orange-light;
        width: 100%;
        text-align: center;
        margin: 0;
        left: 0;
        top: 0;
    }
    &:not(.discount):not(.on-sale)~li.product-flag {
        margin-top: 3.75rem;
    }
    &.new {
        color: #000;
        text-transform: uppercase;
        text-align: center;
        box-shadow: none;
        padding-top: 3px;
        font: 700 15px $font-family;
        background-color: #fff;
        margin-bottom: 5px;
    }
    &.discount-percentage {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        box-shadow: none;
        padding-top: 3px;
        font: 700 15px $font-family;
        background-color: $reduction;
    }
    &.discount-amount {
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        box-shadow: none;
        padding-top: 3px;
        font: 700 15px $font-family;
        background-color: $reduction;
    }
    &.pack {
        display: none;
    }
}

.product-customization {
    margin: $extra-large-space 0;
    .product-customization-item {
        margin: $medium-space 0;
    }
    .product-message {
        background: $gray-light;
        border: none;
        width: 100%;
        height: 3.125rem;
        resize: none;
        padding: 0.625rem;
        &:focus {
            background-color: white;
            outline: 0.1875rem solid $brand-primary;
        }
    }
    .file-input {
        width: 100%;
        opacity: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        height: 2.625rem;
        overflow: hidden;
        position: absolute;
    }
    .customization-message {
        margin-top: 20px;
    }
    .custom-file {
        position: relative;
        background: $gray-light;
        width: 100%;
        height: 2.625rem;
        line-height: 2.625rem;
        text-indent: 0.625rem;
        display: block;
        color: $gray;
        margin-top: $medium-space;
        button {
            z-index: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
    small {
        color: $gray;
    }
}

.product-pack {
    margin-top: $extra-large-space;
    .pack-product-container {
        display: flex;
        justify-content: space-around;
        .pack-product-name {
            flex: 0 0 50%;
            font-size: 0.875rem;
            color: $gray;
        }
        .pack-product-quantity {
            border-left: $gray-light 2px solid;
            padding-left: $small-space;
        }
        .pack-product-name,
        .pack-product-price,
        .pack-product-quantity {
            display: flex;
            align-items: center;
        }
    }
}

.product-refresh {
    margin-top: $medium-space;
}

.social-sharing {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    ul {
        margin-bottom: 0;
    }
    li {
        @include box-shadow-light;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        display: inline-block;
        background-color: white;
        background-repeat: no-repeat;
        background-size: 2rem 2rem;
        background-position: 0.25rem 0.25rem;
        cursor: pointer;
        margin-left: 0.5rem;
        @include transition(all 0.2s ease-in);
        a {
            display: block;
            width: 100%;
            height: 100%;
            white-space: nowrap;
            text-indent: 100%;
            overflow: hidden;
            &:hover {
                color: transparent;
            }
        }
    }
}

#blockcart-modal .cart-content {
    .btn {
        margin-bottom: $small-space;
    }
}

#product {
    #main {
        background-color: #fff;
        .row.hidden-sm-up.text-xs-left {
            background-color: #fff;
        }
    }
}

.up {
    margin-top: 50px;
    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    }
    .back-to-top-btn {
        display: inline-flex;
        background-color: unset;
        padding: 0;
        .back-to-top-box {
            display: inline-flex;
            .back-to-top {
                height: unset;
                width: unset;
                border: 1px solid $gray-darker;
                border-radius: 50%;
                margin-right: 0;
                padding: 15px;
                &:before {
                    content: "";
                    background: url("../img/back-to-top.png");
                    background-repeat: no-repeat;
                    display: block;
                    width: 32px;
                    height: 32px;
                }
            }
            p {
                margin: auto;
                padding-left: 20px;
                font: 400 16px $roboto;
            }
        }
        &:hover,
        &:active {
            background-color: unset;
        }
        &:hover {
            .back-to-top {
                background-color: $gray-darker;
                &:before {
                    content: "";
                    background: url("../img/back-to-top-white.png");
                }
            }
        }
    }
}

#product {
    #footer {
        margin-top: 40px !important;
        @include media-breakpoint-down(sm) {
            margin-top: 0 !important;
        }
        .choose-option {
            @media (max-width: 446px) {
                padding: 14px 15px;
                a {
                    i {
                        margin-right: 7px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .product-cover {
        img {
            width: 100%;
        }
    }
    #product-modal .modal-content .modal-body {
        flex-direction: column;
        margin-left: 0;
        img.product-cover-modal {
            width: 100%;
        }
        .arrows {
            display: none;
        }
    }
    #product-modal .modal-content .modal-body .image-caption {
        width: 100%;
    }
    #blockcart-modal {
        .modal-dialog {
            width: calc(100% - 20px);
        }
        .modal-body {
            padding: 1.875rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    #blockcart-modal {
        .divide-right {
            border-right: none;
        }
        .modal-body {
            padding: 1rem;
        }
        .modal-total {
            font: 500 18px $font-family;
        }
    }
    #blockcart-modal .cart-content {
        float: none;
    }
    .modal-cell {
        vertical-align: middle;
        display: block;
        float: none;
        margin-bottom: 10px;
        text-align: center;
    }
    #js-product-list {
        padding: 0 15px;
    }
    .prod-ref-w {
        flex-direction: column;
        .prod-ref {
            padding-left: 0;
            margin-bottom: 15px;
        }
        .comments_note {
            display: block;
            padding-left: 0;
        }
    }
    .product-quantities {
        display: block;
        vertical-align: unset;
        width: 100%;
        margin-bottom: 10px;
    }
    .product-prices {
        width: 100%;
    }
    .container-2 .favourite-badge {
        justify-content: flex-start !important;
    }
}

#search {
    #content-wrapper {
        margin-left: 0;
    }
    .pagination-sort {
        margin-bottom: 20px;
    }
}

#category,
#prices-drop,
#search {
    .products.row {
        margin: 0 -10px;
    }
    #left-column {
        width: 22%;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    #content-wrapper {
        margin-left: 39px;
        @media (max-width: 1315px) {
            margin-left: 30px;
        }
        @include media-breakpoint-down(lg) {
            margin-left: 20px;
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0;
        }
    }
    .product-flags {
        .product-flag.new,
        .flag-percentage.reduction,
        .flag-amount.reduction {
            font: 700 12px $font-family;
            width: 44px;
            height: 20px;
        }
    }
    .favourite-button .fa-heart:before {
        font-size: 20px;
    }
    .product-miniature {
        .add-to-cart-listing {
            padding-bottom: 20px;
        }
        .add-to-cart-listing .choose_variant {
            font-size: 11px;
            padding: 0.3rem;
            left: 0;
            right: 0;
            bottom: -12px;
            margin-left: auto;
            margin-right: auto;
            min-width: 90px;
        }
        .product-title a {
            font: 500 14px $font-family;
        }
        .price,
        .regular-price,
        .text-price-attributes {
            font: 500 15px $font-family;
            .price_tax_exc {
                font-size: 12px;
                @media (max-width: 400px) {
                    font-size: 11px;
                }
            }
        }
    }
    .availability-listing {
        font-size: 12px;
    }
    article.product-miniature {
        padding: 0 10px;
        @media (max-width: 577px) {
            padding: 0 5px;
        }
        @media (max-width: 360px) {
            width: 100%;
        }
        .thumbnail-container {
            padding: 0 5px;
            margin-bottom: 20px;
            &:hover {
                // background-color: #fbfbfb;
                box-shadow: 0px 0px 13px 6px rgba(0, 0, 0, 0.04);
                transition: box-shadow 0.5s ease-in-out;
                transition: background-color 0.5s ease-in-out;
            }
        }
    }
    .block-categories .collapse-icons {
        @media (max-width: 1077px) {
            right: -30px;
        }
        @media (max-width: 934px) {
            right: -40px;
        }
    }
}

#category div.af_pl_wrapper article.product-miniature {
    @media screen and (max-width: 480px) and (min-width: 420px) {
        width: 100%;
        height: 500px !important;
    }

    @media screen and (max-width: 420px) and (min-width: 320px) {
        width: 100%;
        height: 480px !important;
    }
}

#category div.af_pl_wrapper article.product-miniature .product-description{
    @media (max-width: 480px) {
        width: 100%;
        height: 180px;
    }
    @media (max-width: 400px) {
    }
    @media (max-width: 360px) {
    }
    @media (max-width: 321px) {
    }
}

#search_filter_toggler-top {
    margin: 0 auto 10px;
    display: block;
}

.selectedFilters {
    .af_title {
        margin-bottom: 10px;
        font: 700 18px $font-family;
        color: #000;
        text-transform: initial;
        @include media-breakpoint-down(sm) {
            font: 500 14px $font-family;
            color: $brand-primary;
        }
    }
}

@include media-breakpoint-down(sm) {
    #advanced_layered_navigation_filter .selectedFilters .clearAll {
        padding: 0 4px !important;
    }
}

@include media-breakpoint-down(sm) {
    #advanced_layered_navigation_filter {
        padding: 0 7px;
    }
    #search_filter_toggler-top {
        margin: 0 auto 10px;
        display: block;
    }
    .products-selection .sort-by-row {
        padding: 0;
        .products-sort-order {
            flex: unset;
        }
    }
    #category {
        #left-column {
            width: 100%;
            #search_filters_wrapper {
                margin-left: -30px;
                margin-right: -30px;
            }
            #search_filter_controls {
                text-align: center;
                button {
                    margin: 0 0.5rem;
                }
                margin-bottom: 1rem;
            }
            #search_filters {
                margin-bottom: 0;
                box-shadow: none;
                padding: 0;
                border-top: 1px solid $gray-lighter;
                .facet {
                    .title {
                        cursor: pointer;
                        .collapse-icons .remove {
                            display: none;
                        }
                    }
                    .title[aria-expanded="true"] {
                        .collapse-icons {
                            .add {
                                display: none;
                            }
                            .remove {
                                display: block;
                            }
                        }
                    }
                    padding-top: 0;
                    border-bottom: 1px solid $gray-lighter;
                    .facet-title {
                        color: $gray-darker;
                        text-transform: uppercase;
                    }
                    .h6 {
                        margin-bottom: 0;
                        padding: 0.625rem;
                        display: inline-block;
                    }
                    .navbar-toggler {
                        display: inline-block;
                        padding: 0.625rem 0.625rem 0 0;
                    }
                    .collapse {
                        display: none;
                        &.in {
                            display: block;
                        }
                    }
                    .facet-label {
                        a {
                            margin-top: 0;
                        }
                    }
                    ul {
                        margin-bottom: 0;
                        li {
                            border-top: 1px solid $gray-lighter;
                            padding: 0.625rem;
                        }
                    }
                }
            }
        }
        #content-wrapper {
            width: 100%;
        }
    }
    .mobile-sort-by-row {
        display: inline-block;
        padding-top: 0;
        vertical-align: middle;
        width: 49%;
        float: right;
        .sort-by-row {
            width: 100%;
        }
    }
    .products-sort-order {
        width: 160px;
        display: inline-block;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        .select-title {
            padding: 6px !important;
            margin-left: 0;
            border-radius: 5px !important;
        }
    }
    .products-selection {
        h1 {
            padding-top: 0;
            text-align: center;
            margin-bottom: 1rem;
        }
        .showing {
            padding-top: 1rem;
        }
    }
    #prices-drop #content-wrapper,
    #new-products #content-wrapper,
    #best-sales #content-wrapper {
        width: 100%;
    }
}

#search_filter_toggler {
    font: 400 14px $font-family;
    display: inline-block;
    width: 100%;
    color: $gray-darker;
    padding: 6px 0.625rem;
    cursor: pointer;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    text-align: center;
}

@include media-breakpoint-down(xs) {
    #category {
        #left-column {
            #search_filters_wrapper {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
    }
}

.combination-table {
    th {
        background-color: $gray-lightest;
        font: 500 14px $font-family;
        color: #000;
        border-bottom: unset;
        border-right: unset;
        text-align: left;
        padding: 19px 10px !important;
        white-space: nowrap;
        @media (max-width: 1220px) {
            padding: 19px 24px !important;
        }
        &.add-to-cart-row {
            text-align: center !important;
        }
    }
    th.code-row {
        text-align: left !important;
    }
    tr {
        background-color: unset !important;
        border-bottom: 2px solid $gray-lightest;
        .image-combination {
            display: flex;
            align-items: center;
            .img-small {
                background-color: $gray-lightest;
                width: 64px;
                height: 64px;
                display: block;
                text-align: center;
                margin: 10px 2px 10px 0;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                }
            }
            span {
                display: flex;
                align-items: center;
                &.combo-info {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }
            }
        }
        .image-combination-info {
            .img-small {
                width: 100%;
                height: 100%;
            }
        }
        td {
            font: 400 14px $font-family;
            color: #000;
            #product-availability {
                font: 400 12px $font-family;
                white-space: nowrap;
                margin-top: 5px;
                .product-onorder {
                    padding: 2px 7px;
                    color: #fff;
                    background-color: $orange-lighter;
                }
                .product-available {
                    padding: 2px 7px;
                    color: #fff;
                    background-color: $brand-primary;
                }
                .product-unavailable {
                    padding: 2px 7px;
                    color: #fff;
                    background-color: $reduction;
                }
            }
            .qty {
                display: inline-flex !important;

                border: 1px solid $gray-lighter;
                @include media-breakpoint-down(sm) {
                    display: inline-flex !important;
                }
                button {
                    font: 400 20px $font-family;
                    height: 30px;
                    width: 30px;
                    background-color: #fff;
                    border: unset;
                    color: #000;
                }
                button.minus {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
                button.add {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }
                input {
                    height: 30px;
                    border: unset;
                    font: 400 16px $font-family;
                }
            }
            .add-to-cart-combinations {
                .ladda-button {
                    padding: 4px 21px;
                    background-color: #000 !important;
                    font: 500 10px $font-family;
                    border-radius: 50px;
                    .ladda-label {
                        display: inline-flex;
                        align-items: center;
                        font: 500 10px $font-family;
                        color: #fff;
                    }
                    i.icon-cart {
                        background-image: url("../img/cart-combinations.png");
                        background-repeat: no-repeat;
                        display: block;
                        height: 12px;
                        width: 18px;
                        margin-right: 10px;
                    }
                }
            }
            .shopping-cart.icon-cart {
                background-image: url("../img/cart-combinations.png");
                background-repeat: no-repeat;
                display: block;
                height: 12px;
                width: 18px;
                margin-right: 10px;
            }
            .radio-input {
                padding: 0;
                label {
                    vertical-align: middle;
                    white-space: nowrap;
                }
            }
            &.no-value {
                width: 0px !important;
            }
        }
        .table-product-code {
            text-align: left !important;
            padding-left: 10px;
            padding-right: 10px;
        }
        .table-price {
            .product-prices .current-price span {
                font: 400 14px $font-family !important;
            }
        }
        .total-price {
            .product-prices {
                display: flex !important;
                flex-direction: column-reverse;
                .current-price {
                    span {
                        font: 500 14px $font-family !important;
                        color: #000 !important;
                    }
                }
                .product-discount {
                    .regular-price {
                        font: 500 14px $font-family !important;
                        color: $brand-secondary !important;
                        margin-left: 0px !important;
                    }
                }
            }
            .discount.discount-percentage,
            .discount.discount-amount {
                display: none;
            }
        }
        &[class^="combination-info-"] {
            display: none;

            .other-attributes {
                width: 50%;
                margin: 10px auto;
                td {
                    text-align: left;
                    padding: 7px 0;
                }
            }
            @include media-breakpoint-down(sm) {
                display: none !important;
            }
        }
    }
}

#prices-drop {
    .product-attributes {
        font: 400 14px $font-family;
    }
}

#search {
    .product-attributes {
        font: 400 14px $font-family;
    }
    .add-to-cart-listing {
        .choose_variant {
            bottom: -12px;
        }
    }
    .product-name-variant {
        @media (max-width: 507px) {
            min-height: 108px;
            max-height: 108px;
        }
    }
}

.current-price-combination {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    .regular-price {
        font: 500 14px "Red Hat Display", sans-serif !important;
        color: $brand-secondary !important;
        margin-left: 0px !important;
        text-decoration: line-through;
    }
}