.my-cart-padd {
  @include box-shadow2;
.cart-grid {
    margin-bottom: 1rem;
  }
  .cart-items {
    margin-bottom: 0;
  }
  .cart-item {
    padding: 1rem 0;
    .discount-percentage{
      display: none;
    }
    .product-discount {
      line-height: 10px;
    }
    .bootstrap-touchspin {
      box-shadow: none;
    }
  }
  .cart-summary-line {
    text-align: left;
    padding: 11px;
    clear: both;
    border-top: 1px solid $gray-light;
    p:first-child {
      margin-bottom: 0;
    }
    .label {
      font: 400 14px $font-family;
      padding-left: 0;
      font-weight: normal;
      white-space: inherit;
    }
    .value {
      font: 400 14px $font-family;
      color: $gray-darker;
      float: right;
    }
    &.cart-summary-subtotals {
      .label,
      .value {
        font-weight: normal;
      }
    }
    a {
      padding: 5px 10px;
      font: 400 14px $font-family;
      box-shadow: none;
      text-transform: none;
      color: #fff !important;
    }
  }
  /** CART BODY **/
  .cart-grid-body {
    a.label {
      &:hover {
        color: $brand-primary;
      }
    }
    .card-block {
      padding: 1rem;
      h1 {
        margin-bottom: 0;
      }
    }
    .cart-overview {
      padding: 1rem;
    }
    margin-bottom: 0.75rem;
  }
  /** CART RIGHT **/
  .cart-grid-right {
    .promo-discounts {
      margin-bottom: 0;
      .cart-summary-line {
        .label {
          color: $gray;
          .code {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  .block-promo {
    .promo-code {
      padding: 1.60rem;
      background: $gray-light;
      .alert-danger {
        position: relative;
        margin-top: 1.25rem;
        background: $brand-danger;
        color: white;
        display: none;
        &::after {
          bottom: 100%;
          left: 10%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color:  $brand-danger;
          border-width: 10px;
          margin-left: -10px;
        }
      }
    }
    .promo-input {
      color: $gray-darker;
      border: $gray 1px solid;
      height: 2.5rem;
      text-indent: 0.625rem;
      width: 60%;
      + button {
        margin-top: -4px;
        text-transform: capitalize;
      }
    }
    .cart-summary-line .label,
    .promo-name {
      color: $brand-warning;
      font-weight: 600;
      a {
        font-weight: normal;
        color: $gray-darker;
        display: inline-block;
      }
    }
    .promo-code-button {
      padding-left: 1.25rem;
      margin-bottom: 1.25rem;
      display: inline-block;
    }
    &.promo-highlighted {
      padding: 1.25rem;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  
  .cart-detailed-totals {
    .cart-summary-line {
      background-color: #fff;
    }
  }
  /** CONTENT LEFT **/
  .product-line-grid-left {
    img {
      max-width: 100%;
    }
  }
  /** CONTENT BODY **/
  .product-line-grid-body {
    > .product-line-info {
      > .label {
        padding: 0;
        line-height: inherit;
        text-align: left;
        white-space: inherit;
      }
      > .out-of-stock {
        color: red;
      }
      > .available {
        color: $brand-success;
      }
      > .unit-price-cart {
        padding-left: 0.3125rem;
        font-size: 0.875rem;
        color: $gray;
      }
      > .current-price {
        font: 400 16px $font-family;
      }
      .regular-price{
        font: 400 16px $font-family;
      }
    }
  }
  /** CONTENT LEFT **/
  .product-line-grid-right {
    .cart-line-product-actions,
    .product-price {
      color: $brand-primary;
      font: 600 16px $font-family;
      line-height: 36px;
      .remove-from-cart {
        color: $gray-darker;
        display: inline-block;
        margin-top: 0.3125rem;
      }
    }
  }
} 
  #my-cart {
    display: none;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    right: 0px;
    top: 35px;
  }
  .my-cart-padd{
    .cart-summary-products {
      overflow: hidden;
      padding: 5px;
      .nano {
        height: 145px;
        position: relative;
        width:300px;
      }
      .nano-content {
        position: absolute;
        overflow: scroll;
        overflow-x: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-bottom: 0;
      }
      .nano > .nano-pane {
        background: #595a5c;
        position: absolute;
        width: 6px;
        right: 0px;
        top: 0;
        bottom: 0;
        opacity: .4;
        -webkit-transition: .2s;
        -moz-transition: .2s;
        -o-transition: .2s;
        transition: .2s;
        display: block;
      }
      .nano > .nano-pane > .nano-slider {
        background: #fff;
        opacity: .8;
        position: relative;
        margin: 0 1px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        display: block
      }
      &.no-products {
        position: absolute;
        background-color: white;
        right: 0;
        width: 125px;
        color: $gray-darker;
        font: 400 14px $font-family;
        text-align: center;
        box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07);
      }
      table {
        width: 100%;
        color: #32394d;
        text-align: left;
        padding: 10px;
        th {
          font: 400 10px/12px $font-family;
          color: #909095;
          text-transform: uppercase;
          padding-bottom: 10px;
        }
        td {
          font: 400 11px/13px $font-family;
          padding: 5px 0;
          &.product-image {
            width: 50px;
            img {
              width: 45px;
            }
          }
          &.product-name {
            overflow: hidden;
            width: 110px;
            div {
              max-width: 110px;
              white-space: pre-wrap; /* css-3 */
              white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
              white-space: -pre-wrap; /* Opera 4-6 */
              white-space: -o-pre-wrap; /* Opera 7 */
              word-wrap: break-word; /* Internet Explorer 5.5+ */
              font: 600 11px/13px $font-family;
            }
          }
          &.cart-quantity {
            padding: 5px;
          }
        }
      }
    }
    .cart-details-btn{
      text-transform: uppercase !important;
      border-radius: 50px;
      &:hover{
        background-color: $brand-primary;
      }
    }
}
  
  /*** Responsive part ***/
  @include media-breakpoint-down(md) {
    #my-cart {
      top: 36px;
    }
    #header .header-top #_header_right a.cart-icon {
      padding-bottom: 20px;
    }
  }
  
  @include media-breakpoint-down(sm) {
    .product-line-grid-body {
      margin-bottom: 1rem;
    }
    .cart-summary-line {
      padding: 0;
    }
  }
  
  @include media-breakpoint-down(xs) {
    .cart-items {
      padding: 1rem 0;
    }
    .cart-summary-line {
      padding: 0;
    }
    .cart-item {
      border-bottom: 1px solid $gray-light;
      &:last-child {
        border-bottom: 0;
      }
    }
    .cart-grid-body {
      .cart-overview {
        padding: 0;
      }
      .no-items {
        padding: 1rem;
        display: inline-block;
      }
    }
    .product-line-grid-left {
      padding-right: 0 !important;
    }
    .cart-detailed-actions {
      text-align: center;
    }
    #cart {
      .product-line-grid-right {
        input {
          padding-left: 2px;
          padding-right: 2px;
          width: 2rem;
        }
      }
    }
  }
  
  @media (max-width: 360px) {
    #cart {
      .product-line-grid-right {
        .qty {
          width: 100%;
        }
        .price {
          width: 100%;
        }
      }   
    }
  }
  @media (max-width: 560px) {
    #cart {
      .product-line-grid-right {
        padding-top: 20px;
      }   
    }
  }
