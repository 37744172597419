  #_desktop_user_info {
    .account-info {
      margin-right:4px;
    }
    #account-expanded {
      opacity: 1;
      position: absolute;
      // left: -55px;
      right: -55px;
      top: 35px;
      padding-top: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      text-align: left;
      min-width: 220px;
      background: #fff;
      z-index: 100!important;
      letter-spacing: 0;

      .account-top {
        display: inline-block;
        width: 92%;
        margin: 0 10px 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        color: #777777;
        border-bottom: 1px solid #ccc;
        .account-name {
            font: 700 14px $font-family;
            color: #777777;
        }
      }
      .login-buttons{
        border-bottom: unset !important;
      }
      .login-buttons a {
        padding-left:10px;
        margin-bottom:5px;
        display:block;
      }
  
      .account-middle {
        padding: 5px;
        padding-left: 10px;
        a {
          font: 400 14px $font-family;
          color: #777777;
          &:hover {
            color: $brand-primary;
          }
        }
        .account-links-1 i{
          display: inline-block;
          &:before {
            content: "\f0cb";
            font: 900 13px $font-awesome;
            margin-right: 5px;
            color: $gray-darker;
          }
        }
        .account-links-2 i{
          display: inline-block;
          &:before {
            content: "\f1ad";
            font: 900 13px $font-awesome;
            margin-right: 5px;
            color: $gray-darker;
          }
        }
        .account-links-3 i{
          display: inline-block;
          margin-right: 0;
          font-size: 13px;
          &:before {
            content: "\f007";
            font: 900 13px $font-awesome;
            margin-right: 5px;
            color: $gray-darker;
          }
        }
      }
      .account-bottom {
        margin-top: 5px;
        display:flex;
        justify-content:flex-end;
        margin-bottom: 10px;
        a.btn {

          font: 400 14px $font-family;
          border-radius: 50px;
          background-color: $brand-primary;
          color: #fff!important;
          text-transform: uppercase;
          float: right;
          padding: 5px 10px;
          margin-right: 10px;
          box-shadow: none;
          border: 0;
          overflow: hidden;
          bottom: 15px;
          display: inline-block;
          i {
            font-weight: 600;
            padding: 3px 7px 1px 7px;;
            margin-left: 7px;
            float: right;
          }
        }
      }
    }
  }