.carousel {

    margin-bottom: 25px;
    @include media-breakpoint-down(xs) {
        padding-top: 0;
        margin-bottom: 20px;
    }
    .direction {
        z-index: auto;
        @media (max-width: 1374px) {
            display: none !important;
        }
    }
    .carousel-inner {

        @include media-breakpoint-down(xs) {
            margin-bottom: 0px;
        }
        figure {
            margin: 0;
        }
        @media (max-width: 767px) {
            height: auto;
        }
    }
    .carousel-item {
        img {
            width: 100%;
        }

        .caption {
            position: absolute;
            left: 125px;
            top: 125px;
            max-width: 340px;
            @include media-breakpoint-down(md) {
                left: 50px;
                top: 30px;
            }
            @include media-breakpoint-down(sm) {
                left: 7px;
                top: 5px;
            }
            .caption-description p.paragraph {
                color: #000;
                font: 400 15px $font-family;
                line-height: 1.5;
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            .caption-description h1 {
                color: black;
                font: 700 25px $font-family;
                margin-bottom: 30px;
                @include media-breakpoint-down(sm) {
                    font: 700 20px $font-family;
                    margin-bottom: 12px;
                }
            }
            .caption-description a.btn {
                border: none;
                background: #151515;
                color: white;
                font: 400 14px $font-family;
                border-radius: 34px;

                text-transform: uppercase;
                @include media-breakpoint-down(sm) {
                    font: 400 12px $font-family;
                }
            }
        }
        figure {

        }
    }
    .carousel-control {

        opacity: 1;
        .icon-prev {
            left: -9px !important;
            .icon-prev-image {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                &:before {
                    content: '';
                    background-image: url("../img/arrow-slider-left.png");
                    background-repeat: no-repeat;
                    height: 34px;
                    width: 16px;
                    display: inline-block;
                }
            }
        }
        .icon-next {
            right: 9px !important;
            .icon-next-image {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                &:before {
                    content: '';
                    background-image: url("../img/arrow-slider-right.png");
                    background-repeat: no-repeat;
                    height: 34px;
                    width: 10px;
                    display: inline-block;
                }
            }
        }
        .icon-next-image,
        .icon-prev-image {
            background-color: #fff;
            border-radius: 50%;
        }
        .icon-next,
        .icon-prev {
            top: 45% !important;
        }
        .icon-next,
        .icon-prev {
            &::before {
                content: "";
            }
            i {
                font-size: 3.125rem;
                color: white;
            }
            &:hover {
                i {
                    color: $brand-primary;
                }
            }
        }
        .icon-prev {
            left: 1rem;
        }
        .icon-next {
            right: 2rem;
        }
        &.left,
        &.right {
            background: none;
        }
    }
}
