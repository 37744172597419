#block-reassurance {
    margin-top: 2rem;
    img {
      width: 1.563rem;
      margin-right: 0.625rem;
    }
    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
    #product & {
      span {
        color: #313131;
      }
    }
  }
  #cart,
  #checkout {
    #block-reassurance {
      ul {
        display: block;
        li {
          display: block;
          padding: 10px 0;
        }
      }
    }
  }
  .block-reassurance-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .blockreassurance {
    background-color: $gray-lightest;
    margin-top: 10px;
    padding: 18px 0;
    @include media-breakpoint-down(sm){
      margin-bottom: 0;
    }
    .row {
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    .block {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      color: white;
      flex-grow: 4;
      position: relative;
     
      .block-content {
        display: none;
        position: absolute;
        left: 15px;
        top: 57%;
        right: 15px;
        background-color: #fff;
        text-align: left;
        box-shadow: 0px 0px 11px 0px #dee1e5;
        padding: 10px;
        margin: 39px;
        z-index: 2;
        p {
          font: 400 13px $font-family;
          margin-bottom: 0;
        }
        &:before {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 12px solid #fff;
          content: " ";
          display: block;
          top: -12px;
          left: calc(50% - 15px);
          position: absolute;
        }
        .link {
          font: 400 11px $font-family;
          color: $brand-primary;
        }
        @include media-breakpoint-down(sm){
          left: 7px;
          top: 107%;
          right: 0;
          padding: 5px;
          margin: 0;
        }
      }
      &:hover {
        .block-content {
          display: block;
        }
      }
    }
  .block-icon,
  .block-icon img,
  .block-icon svg {
    height: unset !important;
    max-height: 70px !important;
    margin-bottom: 5px;
  }
  .block-icon{
    @include media-breakpoint-down(xs){
      width: unset !important;
    }
  }
    .block-title,
    .block-icon {
      display: inline-block;
      vertical-align: middle;
    }
    @media (max-width:1060px){
      .block-icon {
        display: block;
      }
    }
    .block-title {
      margin-left: 8px;
      font: 400 13px $font-family;
      color: #000;
      font-weight: 400 !important;
    }
  }
  
  @include media-breakpoint-down(md) {
    #block-reassurance {
      margin-top: 15px;
      ul {
        display: inline-block;
        margin-bottom: 0;
        li {
          display: block;
          padding: 10px 0;
        }
      }
    }
  }
@media (max-width:575px) {
  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
      width: unset !important;
  }
  .blockreassurance .block-title {
      max-width: unset !important;
      padding-top: 0 !important;
  }
}
@media (max-width:508px) {
  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
      max-height: 42px !important;
  }
  .blockreassurance .block-title {
      margin-left: 0;
  }
}

@media (max-width:359px) {
  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
      max-height: 42px !important;
      height: 42px !important;
  }

}